import React from 'react';
import { NavLink, useParams } from 'react-router-dom'
import { Player } from '@lottiefiles/react-lottie-player';
import './Analysis.css';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Analysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: this.props.params.msg
    }
  }

  render() {
    return (
      <main id='analysis'>
        <h1>Cette page est en cours de construction. <br/> Nous vous préviendrons lorsqu'elle sera disponible.</h1>
        <Player
          autoplay
          loop
          src="https://assets4.lottiefiles.com/packages/lf20_AQcLsD.json"
          style={{ width: '300px' }}
        >
        </Player>
        <NavLink className="button" to='/'>Retourner à l'accueil</NavLink>
      </main>
    )
  }
}

export default withParams(Analysis);