import React from 'react'
import './ModifyTenants.css'
import IllustrationModify from '../../../assets/images/updateTenant.svg'
import { Rating, Stack, TextField } from '@mui/material'
import Button from '../../Button/Button'

class ModifyTenant extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data || {
                name: "",
                lastname: "",
                address: "",
                city: "",
                zipcode: "",
                email: "",
                phone: "",
                dateOfBirth: "",
                rating: "",
                comment: ""
            },
            error: {
                name: false,
                lastname: false,
                email: false,
                phone: false,
            },
            saveBtnDisabled: false,
            defaultRating: this.props.data.rating || 0
        }
    }

    handleSave() {
        this.props.onSave(this.state.data)
    }

    formateDate(date) {
        return date.split("-").reverse().join("/")
    }

    formatePhone(phone) {
        let newphone = `0${phone}`
        let phoneList = newphone.split("")
        return `${phoneList[0]}${phoneList[1]} ${phoneList[2]}${phoneList[3]} ${phoneList[4]}${phoneList[5]} ${phoneList[6]}${phoneList[7]} ${phoneList[8]}${phoneList[9]}`
    }

    onDataChange(input) {
        const emailRegex = /[A-Z0-9._%+-]+@[A-Z0-9-]+[.]+[A-Z]{2,3}/i
        const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i
        const nameRegex = /^[a-zA-Z ]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g
        const lastnameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g

        if (input === "name" && this.state.data.name !== "") {
            if (nameRegex.test(this.state.data.name)) {
                this.setState({
                    error: {
                        ...this.state.error,
                        name: false
                    }
                })
            } else {
                this.setState({
                    error: {
                        ...this.state.error,
                        name: true
                    }
                })
            }
        }

        if (input === "lastname" && this.state.data.lastname !== "") {
            if (lastnameRegex.test(this.state.data.lastname)) {
                this.setState({
                    error: {
                        ...this.state.error,
                        lastname: false
                    }
                })
            } else {
                this.setState({
                    error: {
                        ...this.state.error,
                        lastname: true
                    }
                })
            }
        }

        if (input === "email" && this.state.data.email !== "") {
            if (emailRegex.test(this.state.data.email)) {
                this.setState({
                    error: {
                        ...this.state.error,
                        email: false
                    }
                })
            } else {
                this.setState({
                    error: {
                        ...this.state.error,
                        email: true
                    }
                })
            }
        }

        if (input === "phone" && this.state.data.phone !== "") {
            if (phoneRegex.test(this.state.data.phone)) {
                this.setState({
                    error: {
                        ...this.state.error,
                        phone: false
                    }
                })
            } else {
                this.setState({
                    error: {
                        ...this.state.error,
                        phone: true
                    }
                })
            }
        }
    }

    render() {
        return (
            <div id='ModifyTenant'>
                <div id='fond'></div>
                <div id='popUpBox'>
                    <img src={IllustrationModify} alt="" />
                    <div id='save'>
                        <h3>Modifier un locataire</h3>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <TextField value={this.state.data.name || ""} data-label="name" id="standard-basic" error={this.state.error.name} helperText={this.state.error.name ? "Oups... Caractère interdit" : ""} label="Nom" required variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, name: e.target.value } }, () => { this.onDataChange("name") })} />
                            </div>
                            <div className='formElm-box'>
                                <TextField value={this.state.data.lastname || ""} data-label="lastname" id="standard-basic" error={this.state.error.lastname} helperText={this.state.error.lastname ? "Oups... Caractère interdit" : ""} label="Prenom" required variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, lastname: e.target.value } }, () => { this.onDataChange("lastname") })} />
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <TextField value={this.state.data.address || ""} id="standard-basic" label="Adresse" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, address: e.target.value } })} />
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <TextField value={this.state.data.city || ""} id="standard-basic" label="Ville" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, city: e.target.value } })} />
                            </div>
                            <div className='formElm-box'>
                                <TextField value={this.state.data.zipcode || ""} id="standard-basic" label="Code postal" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, zipcode: e.target.value } })} />
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <TextField value={this.state.data.email || ""} id="standard-basic" label="Email" error={this.state.error.email} helperText={this.state.error.email ? "Oups... Vérifiez l'email" : ""} required variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, email: e.target.value } }, () => { this.onDataChange("email") })} />
                            </div>
                            <div className='formElm-box'>
                                <TextField value={this.state.data.phone || ""} id="standard-basic" label="Téléphone" error={this.state.error.phone} helperText={this.state.error.phone ? "Oups... Mauvais numéro" : ""} required variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, phone: e.target.value } }, () => { this.onDataChange("phone") })} />
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <label htmlFor="date">Date de naissance</label>
                                <input value={this.state.data.dateOfBirth || ""} onChange={(e) => this.setState({ data: { ...this.state.data, dateOfBirth: e.target.value } })} type="date" name="date" id='date' />
                            </div>
                            <div className='formElm-box'>
                                <label htmlFor="note">Note</label>
                                <Stack spacing={1}>
                                    <Rating onChange={(e) => this.setState({ data: { ...this.state.data, rating: e.target.value } })} id="note" name="half-rating" defaultValue={this.state.defaultRating} precision={0.5} />
                                </Stack>
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-special'>
                                <TextField value={this.state.data.comment || ""} multiline fullWidth maxRows={5} id="outlined-textarea" label="Commentaires" required variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, comment: e.target.value } })} />
                            </div>
                        </div>
                        <div className='btnBox'>
                            <Button onClick={() => this.props.onClose()} className="neutral alert" text="Annuler" bold={true} />
                            <Button disabled={!((this.state.data.name !== "" && this.state.data.lastname !== "" && this.state.data.email !== "" && this.state.data.phone !== "") && (this.state.error.name === false && this.state.error.lastname === false && this.state.error.email === false && this.state.error.phone === false))} onClick={() => this.handleSave()} text="Enregistrer" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModifyTenant