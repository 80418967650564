import axios from 'axios';
import Config from '../config.json'

/**
 * It makes a GET request to the URL http://localhost:8080/api/tenants and returns the response
 * @returns A promise
 */
async function getAllTenants(id) {
    return axios.get(`${Config.BASE_URL}/api/tenants/${id}`, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

/**
 * It returns a promise that will resolve to the data of the tenant with the given id
 * @param id - The id of the tenant you want to get.
 * @returns The tenant with the id of the parameter passed in.
 */
async function getOneTenant(id) {
    return axios.get(`${Config.BASE_URL}/api/tenants/one/${id}`, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

/**
 * It returns a promise that will resolve to an array of tenant objects
 * @returns An array of objects
 */
async function getAllTenantsAutocomplete(id) {
    return axios.get(`${Config.BASE_URL}/api/tenants/autocomplete/${id}`, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

/**
 * It returns a promise that will resolve to the response of the request
 * @param name - the name of the tenant
 * @returns The tenant with the name that was passed in.
 */
async function searchTenants(id, data) {
    return axios.post(`${Config.BASE_URL}/api/tenants/search/${id}`, data, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

/**
 * It sends a POST request to the server with the data passed as a parameter
 * @param data - The data to be sent to the server, it is converted to JSON.
 * @returns The data that is being returned is the data that is being sent to the server.
 */
async function saveTenant(data) {
    return axios.post(`${Config.BASE_URL}/api/tenants`, data, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

/**
 * It takes an id and data as parameters, and returns a promise that will resolve to the result of an
 * HTTP PUT request to the URL Config.BASE_URL/api/tenants/ with the data as the body of
 * the request
 * @param id - the id of the tenant to modify
 * @param data - the data to be sent to the server
 * @returns The data of the tenant with the id passed in the url.
 */
async function modifyTenant(id, data) {
    return axios.put(`${Config.BASE_URL}/api/tenants/${id}`, data, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

/**
 * It deletes a tenant from the database
 * @param id - the id of the tenant to delete
 * @returns The promise of the axios.delete request.
 */
async function deleteTenant(id) {
    return axios.delete(`${Config.BASE_URL}/api/tenants/${id}`, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

async function deleteManyTenant(data) {
    return axios.delete(`${Config.BASE_URL}/api/tenants`, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        },
        data: data
    })
}

export const tenantsService = {
    getAllTenants,
    getOneTenant,
    getAllTenantsAutocomplete,
    searchTenants,
    saveTenant,
    modifyTenant,
    deleteTenant,
    deleteManyTenant
}

