import React from 'react';
import { NavLink, useParams } from 'react-router-dom'
import { Player } from '@lottiefiles/react-lottie-player';
import Button from '../../components/Button/Button';
import './Settings.css';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: this.props.params.msg
    }
  }

  render() {
    const { userData } = this.props
    return (
      <main id='settings'>
        <div id='settings-header'>
          <div id='settings-header-left'>
            <h1>Réglages</h1>
            <p>Vous avez <span>{this.state.tenantCount}</span> {this.state.tenantCount > 1 ? 'logements' : 'logement'} {this.state.tenantCount > 1 ? 'enregistrés' : 'enregistré'}</p>
          </div>
          <div id='settings-header-right'>
            <p>Votre abonnement actuel : {"gratuit"}</p>
            <Button className="plan" onClick={() => console.log("clicked")} text="Changer mon abonnement" />
          </div>
        </div>
        <div id="box">
          <div className="container">
            <div className='container-elm' id="infos">

              <div id="title">
                <h2>{userData.userLastname} {userData.userName}</h2>
                <p>{userData.userEmail}</p>
              </div>

              <div className="btnBox">
                <Button onClick={() => console.log("clicked")} text="Changer mon email" />
                <Button onClick={() => console.log("clicked")} text="Changer mon mot de passe" />
              </div>

              <div id="address">
                <p>{userData.userAddress}</p>
                <p>{userData.userZipcode} {userData.userCity}</p>
                <p>{userData.addressMore} Batiment A</p>
              </div>

              <div className="btnBox">
                <Button onClick={() => console.log("clicked")} text="Changer mon address" />
              </div>
            </div>
            <div className='container-elm' id="rib-signature">
              <div id="rib">

              </div>
              <div id="signature">

              </div>
            </div>
          </div>
          <div className="container">
            <div className='container-elm' id="lotties">

            </div>
            <div className='container-elm' id="support">

            </div>
          </div>
        </div>
      </main>
    )
  }
}

export default withParams(Settings);