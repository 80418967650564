import React from 'react';
import './App.css';
import './assets/style/hush.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
// view
import Page404 from './views/Page404/Page404';
import Login from './views/Login/Login';
import HomeView from './views/Home/HomeView';
import Dashboard from './views/Dashboard/Dashboard';
import Tenants from './views/Tenants/Tenants';
import Housings from './views/Housings/Housings';
import Contracts from './views/Contracts/Contracts';
import ContractPage from './views/ContractPage/ContractPage';
import ContractSavedPage from './views/ContractSavedPage/ContractSavedPage';
import Analysis from './views/Analysis/Analysis';
import Settings from './views/Settings/Settings';

// components
import Sidebar from './components/Sidebar/Sidebar';
import PrivateRoutes from './components/PrivateRoutes/PrivateRoutes';
import Signup from './views/Signup/Signup';

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      token: undefined || sessionStorage.getItem('token'),
      userData: {}
    }

    this.getUserToken = this.getUserToken.bind(this)
    this.checkToken = this.checkToken.bind(this)
  }

  getUserToken(data) {
    this.setState({
      token: data.token
    })
  }

  checkToken(valid) {
    this.setState({
      userData: valid
    })
  }

  render() {
    return (
      <div id='App'>
        <BrowserRouter>
          <Sidebar userData={this.state.userData}/>
          <main>
            <Routes>
              <Route path="/login" element={<Login getUserToken={this.getUserToken} />} />
              <Route path="/signup" element={<Signup />} />
              <Route element={<PrivateRoutes token={this.state.token} checkToken={this.checkToken}/>}>
                <Route path="*" element={<Page404 />} />
                <Route path="/" element={<HomeView userData={this.state.userData} />} />
                <Route path="/dashboard" element={<Dashboard userData={this.state.userData} />} />
                <Route path="/tenants" element={<Tenants userData={this.state.userData} />} />
                <Route path="/housings" element={<Housings userData={this.state.userData} />} />
                <Route path="/contracts" element={<Contracts userData={this.state.userData} />} />
                <Route path="/contracts/contractpage" element={<ContractPage userData={this.state.userData} />} />
                <Route path="/contracts/contractsavedpage" element={<ContractSavedPage userData={this.state.userData} />} />
                <Route path="/analysis" element={<Analysis userData={this.state.userData} />} />
                <Route path="/settings" element={<Settings userData={this.state.userData} />} />
              </Route>
            </Routes>
          </main>
        </BrowserRouter>
      </div>
    )
  }
}