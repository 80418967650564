import React from 'react'
import { Link } from 'react-router-dom'
import './button.css'

class ButtonLink extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            text: this.props.text,
            to: this.props.to
        }
    }

    render() {
        const classname = this.props.className
        return (
            <button disabled={this.props.disabled} className={`linkBtn ${classname} ${this.state.to === '/logout' ? 'logout' : ''}`}>
                <Link to={this.state.to} state={{data: this.props.data}}>{this.state.text}</Link>
            </button>
        )
    }
}

export default ButtonLink