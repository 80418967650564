import React from 'react';
import { useParams } from 'react-router-dom'
import { Outlet, NavLink } from "react-router-dom"
import './sidebar.css';
import ButtonLink from '../ButtonLink/ButtonLink'
import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import logo from '../../assets/images/logo.svg'

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    }
  }

  componentDidMount() {
    defineElement(lottie.loadAnimation);
  }

  render() {
    const { userData } = this.props

    return (
      <div id='sidebar'>
        <img src={logo} alt="" />
        <ul>
          <li>
            <lord-icon
              style={{ width: "30px", height: "30px", marginLeft: "15px", marginRight: "10px" }}
              trigger="loop-on-hover"
              src="https://cdn.lordicon.com/pxecqsgb.json">
            </lord-icon>
            <NavLink to={`/`}>Accueil</NavLink>
          </li>
          <li>
            <lord-icon
              style={{ width: "30px", height: "30px", marginLeft: "15px", marginRight: "10px" }}
              trigger="loop-on-hover"
              src="https://cdn.lordicon.com/zjscbpdr.json">
            </lord-icon>
            <NavLink to={`/dashboard`}>Tableau de bord</NavLink>
          </li>
          <li>
            <lord-icon
              style={{ width: "30px", height: "30px", marginLeft: "15px", marginRight: "10px" }}
              trigger="loop-on-hover"
              src="https://cdn.lordicon.com/zthozvfn.json">
            </lord-icon>
            <NavLink to={`/tenants`}>Locataires</NavLink>
          </li>
          <li>
            <lord-icon
              style={{ width: "30px", height: "30px", marginLeft: "15px", marginRight: "10px" }}
              trigger="loop-on-hover"
              src="https://cdn.lordicon.com/hjbsbdhw.json">
            </lord-icon>
            <NavLink to={`/housings`}>Logements</NavLink>
          </li>
          <li>
            <lord-icon
              style={{ width: "30px", height: "30px", marginLeft: "15px", marginRight: "10px" }}
              trigger="loop-on-hover"
              src="https://cdn.lordicon.com/ckatldkn.json">
            </lord-icon><NavLink to={`/contracts`}>Contrats</NavLink>
          </li>
          <li>
            <lord-icon
              style={{ width: "30px", height: "30px", marginLeft: "15px", marginRight: "10px" }}
              trigger="loop-on-hover"
              src="https://cdn.lordicon.com/oegrrprk.json">
            </lord-icon><NavLink to={`/analysis`}>Analyse</NavLink>
          </li>
          <li>
            <lord-icon
              style={{ width: "30px", height: "30px", marginLeft: "15px", marginRight: "10px" }}
              trigger="loop-on-hover"
              src="https://cdn.lordicon.com/nnbhwnej.json">
            </lord-icon><NavLink to={`/settings`}>Réglages</NavLink>
          </li>
          <li>
            <lord-icon
              style={{ width: "30px", height: "30px", marginLeft: "15px", marginRight: "10px" }}
              trigger="loop-on-hover"
              src="https://cdn.lordicon.com/eayfxqak.json">
            </lord-icon><a target="_blank" rel="noreferrer" href='https://resaloc-france.com'>Aide</a>
          </li>
        </ul>
        <div id="userInfos">
          <p id='userName'>{userData.userLastname} {userData.userName}</p>
          <p id='userEmail'>{userData.userEmail}</p>
          <ButtonLink to='/login' className={`logout alert outline neutral`} text="Déconnexion" />
        </div>
        <Outlet />
      </div>
    )
  }
}

export default withParams(Sidebar);