import React from 'react'
import './Loader.css'

class Loader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            //
        }
    }

    render() {

        return (
            <div id='loader-box'>
                <div id='loader'/>
            </div>
        )
    }
}

export default Loader