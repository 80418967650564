import React from 'react'
import './Signup.css'
import { TextField } from '@mui/material'
import { Player } from '@lottiefiles/react-lottie-player';
import logo from '../../assets/images/logo.svg'
import illustration from '../../assets/images/signup.svg'
import Button from '../../components/Button/Button'
import ButtonLink from '../../components/ButtonLink/ButtonLink'
import { useNavigate } from 'react-router-dom'
import { usersService } from '../../services/users.service'

function withParams(Component) {
    return props => <Component {...props} navigate={useNavigate()} />;
}
class Signup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            signupData: {},
            error: false,
            showPassword: false
        }

        this.signup = this.signup.bind(this)
    }

    componentDidMount() {
        document.getElementById('sidebar').style.display = 'none'
        sessionStorage.clear()
    }

    signup() {
        usersService.signup(this.state.signupData)
            .then((response) => {
                console.log(response);
                this.setState({
                    error: false
                })
                setTimeout(() => {
                    this.props.navigate('/')
                }, 500);
            })
            .catch((error) => {
                this.setState({
                    error: true
                })
            })
    }

    render() {
        return (
            <div id='signup'>
                <div id='illustration'>
                    {/* <Player
                        autoplay
                        loop
                        src="https://assets5.lottiefiles.com/packages/lf20_w51pcehl.json"
                        style={{ width: '800px' }}
                    >
                    </Player> */}
                    <img src={illustration} alt="" />
                </div>

                <div id="form">
                    <img src={logo} alt="" />
                    <div className="title">
                        <h2>Créer un compte</h2>
                        <p>Commencez votre essai gratuit de 14 jours.</p>
                    </div>
                    <div id='login-form'>
                        <div className='vertical'>
                            <TextField required defaultValue={""} data-label="name" type="name" error={this.state.error} label="Nom" variant="standard" onChange={(e) => this.setState({ signupData: { ...this.state.signupData, name: e.target.value } })} />
                            <TextField required defaultValue={""} data-label="lastname" type="lastname" error={this.state.error} label="Prénom" variant="standard" onChange={(e) => this.setState({ signupData: { ...this.state.signupData, lastname: e.target.value } })} />
                        </div>
                        <TextField required defaultValue={""} data-label="email" type="email" error={this.state.error} label="Email" variant="standard" onChange={(e) => this.setState({ signupData: { ...this.state.signupData, email: e.target.value } })} />
                        <TextField
                            required
                            className='position-relative'
                            defaultValue={""}
                            data-label="password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            error={this.state.error}
                            label="Mot de passe"
                            variant="standard"
                            onChange={(e) => this.setState({ signupData: { ...this.state.signupData, password: e.target.value } })}
                        />
                        <Button disabled={true} onClick={() => this.signup()} text="S'inscrire" />
                        <p id='aux'>Déja inscrit ?
                            <ButtonLink to='/login' className={`alert outline neutral`} text="Se connecter !" />
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withParams(Signup)