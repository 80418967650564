import React from 'react'
import './CreateContract.css'
import IllustrationSave from '../../../assets/images/createContract.svg'
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Button from '../../Button/Button'
import ButtonLink from '../../ButtonLink/ButtonLink'

class CreateContract extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                housing: "",
                tenant: "",
                arrivalDate: "",
                departDate: "",
                keysDelivery: "par le proprietaire",
                keysReturn: "par le proprietaire",
                type: this.props.type,
                place: this.props.place
            },
            error: {
                name: false,
                lastname: false,
                zipcode: false,
                email: false,
                phone: false,
            },
            saveBtnDisabled: true,
            state: this.props.state,
            tenantData: [],
            dataHousings: []
        }
    }

    componentDidMount() {
        this.getTenantsData()
        this.getHousingsData()
    }

    getTenantsData() {
        const options = []
        this.props.dataTenants.forEach(elm => {
            options.push({ label: `${elm.name} ${elm.lastname}`, id: elm._id })
        });
        this.setState({
            tenantData: options,
        })
    }

    getHousingsData() {
        const options = []
        this.props.dataHousings.forEach(elm => {
            options.push({ label: `${elm.name}`, id: elm._id })
        });
        this.setState({
            dataHousings: options,
        })
    }

    render() {
        const { data } = this.state
        return (
            <div id='CreateContract'>
                <div id='fond'></div>
                <div id='popUpBox'>
                    <img src={IllustrationSave} alt="" />
                    <div id='save'>
                        <h3>Générer un contrat</h3>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={this.state.tenantData}
                                    renderInput={(params) => <TextField {...params} label="Locataire" variant="standard" />}
                                    onChange={(e, data) => this.setState({ data: { ...this.state.data, tenant: data.id } })}
                                />
                            </div>
                            <div className='formElm-box'>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={this.state.dataHousings}
                                    renderInput={(params) => <TextField {...params} label="Logement" variant="standard" />}
                                    onChange={(e, data) => this.setState({ data: { ...this.state.data, housing: data.id } })}
                                />
                            </div>
                        </div>
                        <div className='formElm' style={{ marginTop: "20px" }}>
                            <div className='formElm-box'>
                                <label htmlFor="date">Date d'arrivée</label>
                                <input onChange={(e) => this.setState({ data: { ...this.state.data, arrivalDate: e.target.value } })} type="date" name="date" id='date' />
                            </div>
                            <div className='formElm-box'>
                                <label htmlFor="date">Date de départ</label>
                                <input onChange={(e) => this.setState({ data: { ...this.state.data, departDate: e.target.value } })} type="date" name="date" id='date' />
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box' style={{ width: '100%' }}>
                                <FormControl style={{ margin: 0 }} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-standard-label">Remise des clés</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={this.state.data.keysDelivery}
                                        onChange={(e) => this.setState({ data: { ...this.state.data, keysDelivery: e.target.value } })}
                                        label="Remise des clés"
                                    >
                                        <MenuItem value={"par le proprietaire"}>par le proprietaire</MenuItem>
                                        <MenuItem value={"par la reception"}>par la reception</MenuItem>
                                        <MenuItem value={"par boite à clé"}>par boite à clé</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box' style={{ width: '100%' }}>
                                <FormControl style={{ margin: 0 }} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-standard-label">Rendu des clés</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={this.state.data.keysReturn}
                                        onChange={(e) => this.setState({ data: { ...this.state.data, keysReturn: e.target.value } })}
                                        label="Rendu des clés"
                                    >
                                        <MenuItem value={"par le proprietaire"}>par le proprietaire</MenuItem>
                                        <MenuItem value={"par la reception"}>par la reception</MenuItem>
                                        <MenuItem value={"par boite à clé"}>par boite à clé</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='btnBox'>
                            <Button onClick={() => this.props.onClose()} className="neutral alert" text="Annuler" bold={true} />
                            <ButtonLink data={this.state.data} to="/contracts/contractpage" disabled={!(data.housing !== "" && data.tenant !== "" && data.arrivalDate !== "" && data.departDate !== "" && data.keysDelivery !== "" && data.keysReturn !== "")} text="Enregistrer" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateContract