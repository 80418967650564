import React from "react"
import './ContractPage.css'
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Toast from "../../components/Toast/Toast";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { tenantsService } from '../../services/tenants.service';
import { housingsService } from '../../services/housings.service';
import { contractsServices } from '../../services/contracts.service';
import { uploadService } from '../../services/upload.service';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ContractPages from "../../components/ContractPages/ContractPages";
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

function withParams(Component) {
    return props => <Component {...props} navigate={useNavigate()} location={useLocation()} params={useParams()} />;
}

class Create extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            location: this.props.location.state.data,
            type: this.props.location.state.data.type,
            tenantData: {},
            housingData: {},
            rentalData: {
                arrivalDate: "",
                departDate: "",
                keysDelivery: "",
                keysReturn: "",
                place: ""
            },
            toast: {
                display: false,
                text: '',
                type: ''
            },
            disabled: true
        }

        this.printPdf = this.printPdf.bind(this)
        this.save = this.save.bind(this)
        this.disabled = this.disabled.bind(this)
        this.sendPdf = this.sendPdf.bind(this)
    }

    componentDidMount() {
        this.getOneTenant()
        this.getOneHousing()
        this.rentalData()
    }

    getOneTenant() {
        this.setState({
            isLoading: true,
            emptyState: {
                showEmptyState: false,
            }
        })
        tenantsService.getOneTenant(this.state.location.tenant)
            .then((response => {
                this.setState({
                    isLoading: false,
                    tenantData: response.data,
                    emptyState: {
                        showEmptyState: response.data.length === 0 ? true : false,
                        type: "data",
                    }
                })
            }))
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    emptyState: {
                        showEmptyState: true,
                        type: "error"
                    }
                })
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            })
    }

    getOneHousing() {
        this.setState({
            isLoading: true,
            emptyState: {
                showEmptyState: false,
            }
        })
        housingsService.getOneHousing(this.state.location.housing)
            .then((response => {
                this.setState({
                    isLoading: false,
                    housingData: response.data,
                    emptyState: {
                        showEmptyState: response.data.length === 0 ? true : false,
                        type: "data",
                    }
                })
            }))
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    emptyState: {
                        showEmptyState: true,
                        type: "error"
                    }
                })
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            })
    }

    rentalData() {
        this.setState({
            rentalData: {
                arrivalDate: this.props.location.state.data.arrivalDate,
                departDate: this.props.location.state.data.departDate,
                keysDelivery: this.props.location.state.data.keysDelivery,
                keysReturn: this.props.location.state.data.keysReturn,
                place: this.props.location.state.data.place,
            }
        })
    }

    formateDate(date) {
        return date.split("-").reverse().join("/")
    }

    printPdf() {
        const myThis = this
        const date = new Date()
        const currentDate = `${date.getDate()}_${date.getMonth() + 1}_${date.getFullYear()}`
        let pdf = new jsPDF('p', 'pt', 'a4');

        const pages = document.querySelectorAll(".contract-page")

        html2canvas(pages[0], { allowTaint: true }).then(function (canvas) {
            canvas.getContext('2d');
            let imgData = canvas.toDataURL("image/jpeg", 1.0);
            pdf.addImage(imgData, 'JPG', 0, 0, 595, 842);
        }).then(() => {
            html2canvas(pages[1], { allowTaint: true }).then(function (canvas) {
                canvas.getContext('2d');
                let imgData = canvas.toDataURL("image/jpeg", 1.0);
                pdf.addPage();
                pdf.addImage(imgData, 'JPG', 0, 0, 595, 842);
            }).then(() => {
                html2canvas(pages[2], { allowTaint: true }).then(function (canvas) {
                    canvas.getContext('2d');
                    let imgData = canvas.toDataURL("image/jpeg", 1.0);
                    pdf.addPage();
                    pdf.addImage(imgData, 'JPG', 0, 0, 595, 842);
                }).then(() => { pdf.save(`contrat ${myThis.state.housingData.name} ${myThis.state.tenantData.name} ${myThis.state.tenantData.lastname} ${currentDate}`) })
            })
        })
    }

    sendPdf() {
        let pdf = new jsPDF('p', 'pt', 'a4');

        const pages = document.querySelectorAll(".contract-page")

        html2canvas(pages[0], { allowTaint: true }).then(function (canvas) {
            canvas.getContext('2d');
            let imgData = canvas.toDataURL("image/jpeg", 1.0);
            pdf.addImage(imgData, 'JPG', 0, 0, 595, 842);
        }).then(() => {
            html2canvas(pages[1], { allowTaint: true }).then(function (canvas) {
                canvas.getContext('2d');
                let imgData = canvas.toDataURL("image/jpeg", 1.0);
                pdf.addPage();
                pdf.addImage(imgData, 'JPG', 0, 0, 595, 842);
            }).then(() => {
                html2canvas(pages[2], { allowTaint: true }).then(function (canvas) {
                    canvas.getContext('2d');
                    let imgData = canvas.toDataURL("image/jpeg", 1.0);
                    pdf.addPage();
                    pdf.addImage(imgData, 'JPG', 0, 0, 595, 842);
                }).then(() => {
                    uploadService.sendEmail({
                        data: {
                            email: this.state.tenantData.email,
                            name: `${this.state.tenantData.lastname} ${this.state.tenantData.name}`,
                            arrivalDate: this.state.rentalData.arrivalDate,
                            departDate: this.state.rentalData.departDate
                        }, 
                        file: pdf.output("datauristring")
                    })
                        .then((response => {
                            this.setState({
                                isLoading: false,
                                toast: {
                                    display: true,
                                    text: 'contrat envoyer',
                                    type: 'success'
                                }
                            })
                            setTimeout(() => {
                                this.setState({
                                    toast: {
                                        display: false,
                                        text: '',
                                        type: ''
                                    }
                                })
                            }, 5000);
                        }))
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                isLoading: false,
                                toast: {
                                    display: true,
                                    text: 'Probleme l\'envoie',
                                    type: 'error'
                                },
                                emptyState: {
                                    showEmptyState: true,
                                    type: "error"
                                }
                            })
                            setTimeout(() => {
                                this.setState({
                                    toast: {
                                        display: false,
                                        text: '',
                                        type: ''
                                    }
                                })
                            }, 5000);
                        })
                })
            })
        })
    }

    save() {
        const date = new Date()
        const currentDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`

        const saveData = {
            createdBy: this.props.userData.userId,
            tenant: `${this.state.tenantData.name} ${this.state.tenantData.lastname}`,
            houssing: this.state.housingData.name,
            dateOfCreation: currentDate,
            startDate: this.formateDate(this.state.rentalData.arrivalDate),
            endDate: this.formateDate(this.state.rentalData.departDate),
            tenantId: this.state.location.tenant,
            housingId: this.state.location.housing,
            rentalData: this.state.rentalData,
            type: this.state.type
        }

        contractsServices.saveContracts(saveData)
            .then((response => {
                this.setState({
                    isLoading: false,
                    toast: {
                        display: true,
                        text: 'contrat sauvegardé',
                        type: 'success'
                    }
                })
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            }))
            .catch((error) => {
                console.log(error);
                this.setState({
                    isLoading: false,
                    toast: {
                        display: true,
                        text: 'Probleme lors de la sauvegarde',
                        type: 'error'
                    },
                    emptyState: {
                        showEmptyState: true,
                        type: "error"
                    }
                })
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            })
    }

    disabled(disabled) {
        this.setState({
            disabled: disabled
        })
    }

    render() {
        return (
            <div id="Create">
                <Toast show={this.state.toast.display} text={this.state.toast.text} type={this.state.toast.type} />
                <div id='create-header'>
                    <div id='create-header-left'>
                        <h1>Contrats - {this.state.type}</h1>
                        <p>{this.state.housingData.name} - {this.state.tenantData.name} {this.state.tenantData.lastname}</p>
                    </div>
                    <div id='create-header-right'>
                        <ButtonLink to='/contracts' className={`alert outline neutral`} text="Annuler" />
                        <Button onClick={() => this.save()} className={`info outline neutral`} text="Sauvegarder" />
                        <Button disabled={this.state.disabled} onClick={() => this.sendPdf()} className={`success outline neutral`} text="Envoyer par email" />
                        <Button disabled={this.state.disabled} text="Télécharger" onClick={() => this.printPdf()} />
                    </div>
                </div>
                <div id="create-containers">
                    <ContractPages userData={this.props.userData} disabled={this.disabled} tenantData={this.state.tenantData} housingData={this.state.housingData} rentalData={this.state.rentalData} />
                    <div id='save'>
                        <h3>Modifier le contrat</h3>
                        <div className='formElm' style={{ marginTop: "20px" }}>
                            <div className='formElm-box'>
                                <label htmlFor="date">Date d'arrivée</label>
                                <input value={this.state.rentalData.arrivalDate} onChange={(e) => this.setState({ rentalData: { ...this.state.rentalData, arrivalDate: e.target.value } })} type="date" name="date" id='date' />
                            </div>
                            <div className='formElm-box'>
                                <label htmlFor="date">Date de départ</label>
                                <input value={this.state.rentalData.departDate} onChange={(e) => this.setState({ rentalData: { ...this.state.rentalData, departDate: e.target.value } })} type="date" name="date" id='date' />
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box' style={{ width: '100%' }}>
                                <FormControl style={{ margin: 0 }} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-standard-label">Remise des clés</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={this.state.rentalData.keysDelivery}
                                        onChange={(e) => this.setState({ rentalData: { ...this.state.rentalData, keysDelivery: e.target.value } })}
                                        label="Remise des clés"
                                    >
                                        <MenuItem value={"par le proprietaire"}>par le proprietaire</MenuItem>
                                        <MenuItem value={"par la reception"}>par la reception</MenuItem>
                                        <MenuItem value={"par boite à clé"}>par boite à clé</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box' style={{ width: '100%' }}>
                                <FormControl style={{ margin: 0 }} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-standard-label">Rendu des clés</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={this.state.rentalData.keysReturn}
                                        onChange={(e) => this.setState({ rentalData: { ...this.state.rentalData, keysReturn: e.target.value } })}
                                        label="Rendu des clés"
                                    >
                                        <MenuItem value={"par le proprietaire"}>par le proprietaire</MenuItem>
                                        <MenuItem value={"par la reception"}>par la reception</MenuItem>
                                        <MenuItem value={"par boite à clé"}>par boite à clé</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div id="infos">
                            <p>
                                Avant de télécharger votre contrat :
                            </p>

                            <ol>
                                <li>Vérifier si la qualité du bailleur est cocheée</li>
                                <li>Pensez à vérifier les diverses informations du contrat avant de l'envoyer au locataire</li>
                                <li>Sauvegarder le si vous voulez y revenir plus tard</li>
                            </ol>

                            <p>
                                Si jamais vous remarquez un problème sur les informations du locataire ou du logement, vous pouvez sauvegarder le logement en cliquant sur le bouton "Sauvegarder", puis modifier le locataire ou le logement depuis sa page correspondante.
                            </p>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withParams(Create);