import axios from 'axios';
import Config from '../config.json'

async function uploadTenantList(id, data) {
    return axios.post(`${Config.BASE_URL}/api/uploads/uploadtenants/${id}`, data, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

async function uploadHousingList(id, formdata, data) {
    return axios.post(`${Config.BASE_URL}/api/uploads/uploadhousings/id/${id}/type/${data.type}/status/${data.status}`, formdata, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

async function sendEmail(data) {
    return axios.post(`${Config.BASE_URL}/api/uploads/email`, data, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

export const uploadService = {
    uploadTenantList,
    uploadHousingList,
    sendEmail
}

