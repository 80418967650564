import axios from 'axios';
import Config from '../config.json'

async function getAllContracts(id) {
    return axios.get(`${Config.BASE_URL}/api/contracts/${id}`, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

async function saveContracts(data) {
    return axios.post(`${Config.BASE_URL}/api/contracts`, data, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

async function deleteManyContracts(data) {
    return axios.delete(`${Config.BASE_URL}/api/contracts`, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        },
        data: data
    })
}

async function modifyContracts(id, data) {
    return axios.put(`${Config.BASE_URL}/api/contracts/${id}`, data, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })

}

export const contractsServices = {
    getAllContracts,
    saveContracts,
    deleteManyContracts,
    modifyContracts
}

