import React from "react"
import './Contracts.css'
import BaseTable from '../../components/BaseTable/BaseTable'
import CreateContract from '../../components/ContractPopUp/CreateContract/CreateContract'
import { tenantsService } from '../../services/tenants.service';
import { housingsService } from '../../services/housings.service';
import Toast from "../../components/Toast/Toast";
import { contractsServices } from "../../services/contracts.service";
import DialogBox from "../../components/DialogBox/DialogBox";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";

function withParams(Component) {
    return props => <Component {...props} navigate={useNavigate()} />;
}

class Contracts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            contractsCount: 0,
            emptyState: {
                showEmptyState: false,
            },
            isLoading: false,
            data: [],
            dataTenants: [],
            dataHousings: [],
            dialogManyDelete: {
                display: false,
                text: 'lorem ipsum dolor amet net lotem ipsum dolor amet net',
                type: 'alert'
            },
            columns: [
                {
                    id: 'houssing',
                    label: 'Logement',
                    minWidth: 170,
                    sort: true,
                    type: 'string',
                    ad: 'dsc'
                },
                {
                    id: 'type',
                    label: 'Type',
                    minWidth: 170,
                    sort: true,
                    type: 'string',
                    ad: 'dsc'
                },
                {
                    id: 'tenant',
                    label: 'Locataire',
                    minWidth: 170,
                },
                {
                    id: 'dateOfCreation',
                    label: 'Date de création',
                    minWidth: 170
                },
                {
                    id: 'startDate',
                    label: 'Date de début',
                    minWidth: 100,
                },
                {
                    id: 'endDate',
                    label: 'Date de fin',
                    minWidth: 170,
                }
            ],
            createContractPopUp: {
                display: false,
                type: ""
            },
            toast: {
                display: false,
                text: '',
                type: ''
            },
            toDeleteList: [],
        }
        this.getAllTenantsAutocomplete = this.getAllTenantsAutocomplete.bind(this)
        this.getAllHousingsAutocomplete = this.getAllHousingsAutocomplete.bind(this)
        this.getAllContracts = this.getAllContracts.bind(this)
        this.openCreateContractPopUp = this.openCreateContractPopUp.bind(this)
        this.sortStringBaseTable = this.sortStringBaseTable.bind(this)
        this.sortNumberBaseTable = this.sortNumberBaseTable.bind(this)
        this.sortBaseTable = this.sortBaseTable.bind(this)
        this.onClosePopup = this.onClosePopup.bind(this)
        this.handleCheckBox = this.handleCheckBox.bind(this)
        this.handleManyDelete = this.handleManyDelete.bind(this)
        this.confirmManyDelete = this.confirmManyDelete.bind(this)
        this.modifySavedContract = this.modifySavedContract.bind(this)
        this.onCloseDialogBox = this.onCloseDialogBox.bind(this)
    }

    componentDidMount() {
        this.getAllTenantsAutocomplete()
        this.getAllHousingsAutocomplete()
        this.getAllContracts()
    }

    getAllTenantsAutocomplete() {
        this.setState({
            isLoading: true,
            emptyState: {
                showEmptyState: false,
            }
        })
        tenantsService.getAllTenantsAutocomplete(this.props.userData.userId)
            .then((response => {
                this.setState({
                    isLoading: false,
                    dataTenants: [...response.data],
                    emptyState: {
                        showEmptyState: response.data.length === 0 ? true : false,
                        type: "data",
                    }
                })
            }))
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    emptyState: {
                        showEmptyState: true,
                        type: "error"
                    }
                })
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            })
    }

    getAllHousingsAutocomplete() {
        this.setState({
            isLoading: true,
            emptyState: {
                showEmptyState: false,
            }
        })
        housingsService.getAllHousingsAutocomplete(this.props.userData.userId)
            .then((response => {
                this.setState({
                    isLoading: false,
                    dataHousings: [...response.data],
                    emptyState: {
                        showEmptyState: response.data.length === 0 ? true : false,
                        type: "data",
                    }
                })
            }))
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    emptyState: {
                        showEmptyState: true,
                        type: "error"
                    }
                })
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            })
    }

    getAllContracts() {
        this.setState({
            isLoading: true,
            emptyState: {
                showEmptyState: false,
            }
        })
        contractsServices.getAllContracts(this.props.userData.userId)
            .then((response => {
                this.setState({
                    isLoading: false,
                    data: [...response.data],
                    contractsCount: response.data.length,
                    emptyState: {
                        showEmptyState: response.data.length === 0 ? true : false,
                        type: "data",
                    }
                })
            }))
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    emptyState: {
                        showEmptyState: true,
                        type: error.response.status === 401 ? "unauthorized" : "error"
                    }
                })
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            })
    }

    openCreateContractPopUp(data) {
        this.setState({
            createContractPopUp: {
                display: true,
                type: data
            },
        })
    }

    modifySavedContract(data) {
        this.props.navigate("/contracts/contractsavedpage", {state: data})
    }

    sortStringBaseTable(data, sort) {
        data.sort(function (a, b) {
            const nameA = a[sort.column].toUpperCase();
            const nameB = b[sort.column].toUpperCase();
            if (sort.ad === 'dsc') {
                document.getElementById(`${sort.column}-sort`).style.transform = "rotate(0)"
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
            } else if (sort.ad === 'asc') {
                document.getElementById(`${sort.column}-sort`).style.transform = "rotate(180deg)"
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA < nameB) {
                    return 1;
                }
            }
            return 0;
        })
    }

    sortNumberBaseTable(data, sort) {
        if (sort.ad === 'dsc') {
            data.sort((a, b) => {
                let sup = (a.rating > b.rating)
                let inf = (a.rating < b.rating) ? -1 : 0
                return sup ? 1 : inf
            })
            document.getElementById(`${sort.column}-sort`).style.transform = "rotate(0)"
        } else if (sort.ad === 'asc') {
            data.sort((a, b) => {
                let sup = (a.rating < b.rating)
                let inf = (a.rating > b.rating) ? -1 : 0
                return sup ? 1 : inf
            })
            document.getElementById(`${sort.column}-sort`).style.transform = "rotate(180deg)"
        }
    }

    sortBaseTable(sort) {
        let data = this.state.data
        let columns = this.state.columns
        switch (sort.type) {
            case "string":
                this.sortStringBaseTable(data, sort)

                let nameColumn = { ...columns[0] }
                nameColumn.ad = sort.ad
                columns[0] = nameColumn
                this.setState({
                    data: data,
                })
                break;

            case "number":
                this.sortNumberBaseTable(data, sort)

                let ratingColumn = { ...columns[4] }
                ratingColumn.ad = sort.ad
                columns[4] = ratingColumn

                this.setState({
                    data: data
                })
                break;

            default:
                break;
        }
    }

    onClosePopup() {
        this.setState({
            createContractPopUp: {
                display: false
            },
        })
    }

    handleCheckBox(data) {
        const toDeleteList = this.state.toDeleteList
        if (toDeleteList.indexOf(data) === -1) {
            toDeleteList.push(data)
            this.setState({
                toDeleteList: toDeleteList
            })
        } else {
            toDeleteList.splice(toDeleteList.indexOf(data), 1)
            this.setState({
                toDeleteList: toDeleteList
            })
        }
    }

    onCloseDialogBox() {
        this.setState({
            dialogManyDelete: {
                display: false,
                text: 'lorem ipsum dolor amet net lotem ipsum dolor amet net',
                type: 'info'
            },
        })
    }
    
    handleManyDelete() {
        this.setState({
            dialogManyDelete: {
                display: true,
                text: `Etes vous sur de vouloir supprimer ${this.state.toDeleteList.length} contrats ?`,
                type: 'alert'
            },
        })
    }

    confirmManyDelete() {
        let data = this.state.toDeleteList
        this.setState({
            isLoading: true,
            emptyState: {
                showEmptyState: false,
            }
        })
        this.onCloseDialogBox()
        contractsServices.deleteManyContracts({ data })
            .then((response => {
                this.getAllContracts()
                this.setState({
                    isLoading: false,
                    toast: {
                        display: true,
                        text: 'Contrats supprimés',
                        type: 'success'
                    },
                    toDeleteList: []
                })
                const allCheckbox = document.querySelectorAll('.checkbox')
                allCheckbox.forEach(elm => {
                    elm.checked = false
                });
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            }))
            .catch((error) => {
                if (error.response.status === 500) {
                    this.setState({
                        isLoading: false,
                        emptyState: {
                            showEmptyState: true,
                            type: "error"
                        }
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        toast: {
                            display: true,
                            text: 'Erreur lors de la suppression',
                            type: 'error'
                        }
                    })
                    setTimeout(() => {
                        this.onCloseDialogBox()
                        this.setState({
                            toast: {
                                display: false,
                                text: '',
                                type: ''
                            },
                            emptyState: {
                                showEmptyState: true,
                                type: "error"
                            }
                        })
                    }, 5000);
                }
            })
    }

    render() {
        return (
            <div id="Contracts">
                <Toast show={this.state.toast.display} text={this.state.toast.text} type={this.state.toast.type} />
                {this.state.dialogManyDelete.display && <DialogBox text={this.state.dialogManyDelete.text} btnText={"Supprimer"} type={this.state.dialogManyDelete.type} onConfirme={this.confirmManyDelete} onClose={this.onCloseDialogBox} />}
                {this.state.createContractPopUp.display && <CreateContract place={this.props.userData.userCity} type={this.state.createContractPopUp.type} dataTenants={this.state.dataTenants} dataHousings={this.state.dataHousings} onClose={this.onClosePopup} />}
                <div id='contracts-header'>
                    <div id='contracts-header-left'>
                        <h1>Contrats</h1>
                        <p>Vous avez <span>{this.state.contractsCount}</span> {this.state.tenantCount > 1 ? 'contrats' : 'contrat'} {this.state.tenantCount > 1 ? 'enregistrés' : 'enregistré'}</p>
                    </div>
                    <div id='contracts-header-right'>
                        <Button className={`info outline neutral`} onClick={() => this.openCreateContractPopUp("Location non meublé")} text="Location non meublé" />
                        <Button className={`info outline neutral`} onClick={() => this.openCreateContractPopUp("Location meublé")} text="Location meublé" />
                        <Button onClick={() => this.openCreateContractPopUp("Location saisonnières")} text="Location saisonnières" />
                    </div>
                </div>

                <BaseTable showDeleteBtn={this.state.toDeleteList.length > 0 ? true : false} emptyState={this.state.emptyState} isLoading={this.state.isLoading} data={this.state.data} columns={this.state.columns} handleCheckBox={this.handleCheckBox} handleManyDelete={this.handleManyDelete} sort={this.sortBaseTable} getInfos={this.modifySavedContract} />
            </div>
        )
    }
}

export default withParams(Contracts)