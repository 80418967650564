import React from 'react'
import './ReadTenants.css'
import IllustrationRead from '../../../assets/images/readTenants.svg'
import { Rating, Stack, TextField } from '@mui/material'
import Button from '../../Button/Button'

class TenantPopUp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data || {
                name: "",
                lastname: "",
                address: "",
                city: "",
                zipcode: "",
                email: "",
                phone: "",
                dateOfBirth: "",
                rating: "",
                comment: ""
            },
            error: {
                name: false,
                lastname: false,
                email: false,
                phone: false,
            },
        }
    }

    handleModify() {
        this.props.onModify(this.state.data)
    }

    handleDelete() {
        this.props.onDelete({ id: this.state.data._id, name: this.state.data.name, lastname: this.state.data.lastname })
    }

    formateDate(date) {
        return date === undefined ? "" : date.split("-").reverse().join("/")
    }

    formatePhone(phone) {
        if (phone === undefined) {
            return ""
        }
        let newphone = phone
        let phoneList = newphone.split("")
        return `${phoneList[0]}${phoneList[1]} ${phoneList[2]}${phoneList[3]} ${phoneList[4]}${phoneList[5]} ${phoneList[6]}${phoneList[7]} ${phoneList[8]}${phoneList[9]}`
    }

    render() {
        return (
            <div id='ReadTenant'>
                <div id='fond'></div>
                <div id='popUpBox'>
                    <img src={IllustrationRead} alt="" />
                    <div id='save'>
                        <h3>Fiche locataire</h3>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <TextField value={this.state.data.name || ""} data-label="name" id="standard-basic" error={this.state.error.name} helperText={this.state.error.name ? "Oups... Caractère interdit" : ""} label="Nom" required variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, name: e.target.value } }, () => { this.onDataChange("name") })} />
                            </div>
                            <div className='formElm-box'>
                                <TextField value={this.state.data.lastname || ""} data-label="lastname" id="standard-basic" error={this.state.error.lastname} helperText={this.state.error.lastname ? "Oups... Caractère interdit" : ""} label="Prenom" required variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, lastname: e.target.value } }, () => { this.onDataChange("lastname") })} />
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <TextField value={this.state.data.address || ""} id="standard-basic" label="Adresse" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, address: e.target.value } })} />
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <TextField value={this.state.data.city || ""} id="standard-basic" label="Ville" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, city: e.target.value } })} />
                            </div>
                            <div className='formElm-box'>
                                <TextField value={this.state.data.zipcode || ""} id="standard-basic" label="Code postal" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, zipcode: e.target.value } })} />
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <TextField value={this.state.data.email || ""} id="standard-basic" label="Email" error={this.state.error.email} helperText={this.state.error.email ? "Oups... Vérifiez l'email" : ""} required variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, email: e.target.value } }, () => { this.onDataChange("email") })} />
                            </div>
                            <div className='formElm-box'>
                                <TextField value={this.state.data.phone || ""} id="standard-basic" label="Téléphone" error={this.state.error.phone} helperText={this.state.error.phone ? "Oups... Mauvais numéro" : ""} required variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, phone: e.target.value } }, () => { this.onDataChange("phone") })} />
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <label htmlFor="date">Date de naissance</label>
                                <input value={this.state.data.dateOfBirth || ""} onChange={(e) => this.setState({ data: { ...this.state.data, dateOfBirth: e.target.value } })} type="date" name="date" id='date' />
                            </div>
                            <div className='formElm-box'>
                                <label htmlFor="note">Note</label>
                                <Stack spacing={1}>
                                    <Rating onChange={(e) => this.setState({ data: { ...this.state.data, rating: e.target.value } })} id="note" name="half-rating" defaultValue={this.state.defaultRating} precision={0.5} />
                                </Stack>
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-special'>
                                <TextField value={this.state.data.comment || ""} multiline fullWidth maxRows={5} id="outlined-textarea" label="Commentaires" required variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, comment: e.target.value } })} />
                            </div>
                        </div>
                        <div className='btnBox'>
                            <Button onClick={() => this.props.onClose()} className="neutral alert" text="Annuler" bold={true} />
                            <Button onClick={() => this.handleDelete()} className="background-alert" text="Supprimer" />
                            <Button onClick={() => this.handleModify()} text="Modifier" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default TenantPopUp