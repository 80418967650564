import React from 'react'
import './ReadHousing.css'
import IllustrationRead from '../../../assets/images/readHousing.svg'
import Button from '../../Button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'

class ReadHousing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data || {},
            state: this.props.state,
            prestationPage: false,
            error: {
                name: false,
                tenantsMaxNb: false,
                surface: false,
                nbOfRooms: false,
                nbOfKey: false,
                rent: false,
                charges: false,
                touristTaxe: false,
                guarantee: false,
                arrivalHours: false,
                departHours: "",
            },
        }
    }

    handleModify() {
        this.props.onModify(this.state.data)
    }

    handleDelete() {
        this.props.onDelete({ id: this.state.data._id, name: this.state.data.name, lastname: this.state.data.lastname })
    }

    changePage() {
        let show = this.state.prestationPage
        this.setState({
            prestationPage: !show
        })
    }

    render() {
        console.log(this.state.data);
        let statusColor = '#5ab249'

        switch (this.state.data.status) {
            case 'Indisponible':
                statusColor = '#f00000 !important'
                break;
            case 'Disponible':
                statusColor = '#5ab249 !important'
                break;
            case 'En travaux':
                statusColor = '#f15a24 !important'
                break;

            default:
                statusColor = '#5ab249 !important'
                break;
        }

        return (
            <div id='ReadHousing'>
                <div id='fond'></div>
                <div id='popUpBox'>
                    <div id="img">
                        <img src={IllustrationRead} alt="" />
                    </div>

                    <div id='container'>
                        <h3>Fiche logement</h3>

                        <div id="header">
                            <TextField disabled className='header-elm' data-label="name" defaultValue={this.state.data.name} id="standard-basic" error={this.state.error.name} helperText={this.state.error.name ? "Oups... Caractère interdit" : ""} label="Nom" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, name: e.target.value } }, () => { this.onDataChange("name") })} />
                            <FormControl disabled className='header-elm' style={{ margin: 0 }} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={this.state.data.type}
                                    onChange={(e) => this.setState({ data: { ...this.state.data, type: e.target.value } })}
                                    label="Type"
                                >
                                    <MenuItem value={"Appartement"}>Appartement</MenuItem>
                                    <MenuItem value={"Chateau"}>Chateau</MenuItem>
                                    <MenuItem value={"Chambre étudiante"}>Chambre étudiante</MenuItem>
                                    <MenuItem value={"Gîte"}>Gîte</MenuItem>
                                    <MenuItem value={"Maison individuelle"}>Maison individuelle</MenuItem>
                                    <MenuItem value={"Studio"}>Studio</MenuItem>
                                    <MenuItem value={"Villa"}>Villa</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl disabled className='header-elm' style={{ margin: 0 }} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id={this.state.data.status.split(" ").join("-")}
                                    value={this.state.data.status}
                                    onChange={(e) => this.setState({ data: { ...this.state.data, status: e.target.value } })}
                                    label="Type"
                                    style={{ color: statusColor }}
                                >
                                    <MenuItem style={{ color: '#5ab249' }} value={"Disponible"}>Disponible</MenuItem>
                                    <MenuItem style={{ color: '#f00000' }} value={"Indisponible"}>Indisponible</MenuItem>
                                    <MenuItem style={{ color: '#f15a24' }} value={"En travaux"}>En travaux</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        {!this.state.prestationPage && <div className='sub-container'>
                            <div className='save left'>
                                <div className='formElm full-width'>
                                    <div className='formElm-box full-width'>
                                        <TextField disabled id="standard-basic" defaultValue={this.state.data.address} label="Adresse" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, address: e.target.value } })} />
                                    </div>
                                </div>
                                <div className='formElm'>
                                    <div className='formElm-box'>
                                        <TextField disabled id="standard-basic" defaultValue={this.state.data.city} label="Ville" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, city: e.target.value } })} />
                                    </div>
                                    <div className='formElm-box'>
                                        <TextField disabled id="standard-basic" defaultValue={this.state.data.zipcode} label="Code postal" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, zipcode: e.target.value } })} />
                                    </div>
                                </div>
                                <div className='formElm full-width'>
                                    <div className='formElm-box full-width'>
                                        <TextField disabled id="standard-basic" defaultValue={this.state.data.addressMore} label="Compléments d'adresse" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, addressMore: e.target.value } })} />
                                    </div>
                                </div>
                                <div className='formElm next'>
                                    <div className='formElm-box'>
                                        <TextField disabled id="standard-basic" defaultValue={this.state.data.arrivalHours} label="Heure arrivé (00:00)" variant="standard" error={this.state.error.arrivalHours} helperText={this.state.error.arrivalHours ? "Oups... Mauvais format" : ""} onChange={(e) => this.setState({ data: { ...this.state.data, arrivalHours: e.target.value } }, () => { this.onDataChange("arrivalHours") })} />
                                    </div>
                                    <div className='formElm-box'>
                                        <TextField disabled id="standard-basic" defaultValue={this.state.data.departHours} label="Heure départ (00:00)" variant="standard" error={this.state.error.departHours} helperText={this.state.error.departHours ? "Oups... Mauvais format" : ""} onChange={(e) => this.setState({ data: { ...this.state.data, departHours: e.target.value } }, () => { this.onDataChange("departHours") })} />
                                    </div>
                                </div>
                                <div className='formElm next'>
                                    <div className='formElm-box full-width'>
                                        <TextField disabled id="standard-basic" defaultValue={this.state.data.rules.join(", ")} label="Mesures à respecter (séparer par une virgule)" variant="standard" onChange={(e) => this.onRulesChange(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <div className='save right'>
                                <div id='roomsInfos'>
                                    <div className="formElm">
                                        <FormControl disabled className='header-elm' style={{ margin: 0, width: "100%" }} variant="standard" sx={{ m: 1 }}>
                                            <InputLabel id="demo-simple-select-standard-label">Chambres</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={this.state.data.nbChamber}
                                                onChange={(e) => this.setState({ data: { ...this.state.data, nbChamber: e.target.value } })}
                                                label="Type"
                                            >
                                                <MenuItem value={"1"}>1</MenuItem>
                                                <MenuItem value={"2"}>2</MenuItem>
                                                <MenuItem value={"3"}>3</MenuItem>
                                                <MenuItem value={"4"}>4</MenuItem>
                                                <MenuItem value={"5"}>5</MenuItem>
                                                <MenuItem value={"plus de 5"}>plus de 5</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl disabled className='header-elm' style={{ margin: 0, width: "100%" }} variant="standard" sx={{ m: 1 }}>
                                            <InputLabel id="demo-simple-select-standard-label">Salle de bains</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={this.state.data.nbSDB}
                                                onChange={(e) => this.setState({ data: { ...this.state.data, nbSDB: e.target.value } })}
                                                label="Type"
                                            >
                                                <MenuItem value={"1"}>1</MenuItem>
                                                <MenuItem value={"2"}>2</MenuItem>
                                                <MenuItem value={"3"}>3</MenuItem>
                                                <MenuItem value={"4"}>4</MenuItem>
                                                <MenuItem value={"5"}>5</MenuItem>
                                                <MenuItem value={"plus de 5"}>plus de 5</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="formElm">
                                        <FormControl disabled className='header-elm' style={{ margin: 0, width: "100%" }} variant="standard" sx={{ m: 1 }}>
                                            <InputLabel id="demo-simple-select-standard-label">Salles à manger</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={this.state.data.nbDinRoom}
                                                onChange={(e) => this.setState({ data: { ...this.state.data, nbDinRoom: e.target.value } })}
                                                label="Type"
                                            >
                                                <MenuItem value={"1"}>1</MenuItem>
                                                <MenuItem value={"2"}>2</MenuItem>
                                                <MenuItem value={"3"}>3</MenuItem>
                                                <MenuItem value={"4"}>4</MenuItem>
                                                <MenuItem value={"5"}>5</MenuItem>
                                                <MenuItem value={"plus de 5"}>plus de 5</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl disabled className='header-elm' style={{ margin: 0, width: "100%" }} variant="standard" sx={{ m: 1 }}>
                                            <InputLabel id="demo-simple-select-standard-label">Salon</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={this.state.data.nbLivRoom}
                                                onChange={(e) => this.setState({ data: { ...this.state.data, nbLivRoom: e.target.value } })}
                                                label="Type"
                                            >
                                                <MenuItem value={"1"}>1</MenuItem>
                                                <MenuItem value={"2"}>2</MenuItem>
                                                <MenuItem value={"3"}>3</MenuItem>
                                                <MenuItem value={"4"}>4</MenuItem>
                                                <MenuItem value={"5"}>5</MenuItem>
                                                <MenuItem value={"plus de 5"}>plus de 5</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='formElm'>
                                        <div className='formElm-box full-width'>
                                            <TextField disabled id="standard-basic" defaultValue={this.state.data.othersRooms.join(", ")} label="Autres pièces (séparer par une virgule)" variant="standard" onChange={(e) => this.onOthersRoomsChange(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className='formElm next'>
                                    <div className='formElm-box'>
                                        <TextField disabled id="standard-basic" error={this.state.error.tenantsMaxNb} helperText={this.state.error.tenantsMaxNb ? "Oups... Caractère interdit" : ""} defaultValue={this.state.data.tenantsMaxNb} label="Nb de locataires"  variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, tenantsMaxNb: e.target.value } }, () => { this.onDataChange("tenantsMaxNb") })} />
                                    </div>
                                    <div className='formElm-box'>
                                        <TextField disabled id="standard-basic" error={this.state.error.nbOfKey} helperText={this.state.error.nbOfKey ? "Oups... Caractère interdit" : ""} defaultValue={this.state.data.nbOfKey} label="Jeu de clé(s)"  variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, nbOfKey: e.target.value } }, () => { this.onDataChange("nbOfKey") })} />
                                    </div>
                                    <div className='formElm-box'>
                                        <TextField disabled
                                            id="standard-basic"
                                            error={this.state.error.surface}
                                            helperText={this.state.error.surface ? "Oups... Caractère interdit" : ""}
                                            defaultValue={this.state.data.surface}
                                            label="Surface"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">m²</InputAdornment>
                                            }}
                                            
                                            variant="standard"
                                            onChange={(e) => this.setState({ data: { ...this.state.data, surface: e.target.value } }, () => { this.onDataChange("surface") })} />
                                    </div>
                                </div>
                                <div className='formElm next'>
                                    <div className='formElm-special'>
                                        <TextField disabled multiline fullWidth maxRows={5} defaultValue={this.state.data.comment} id="outlined-textarea" label="Commentaires" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, comment: e.target.value } })} />
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {this.state.prestationPage && <div className='sub-container'>
                            <div className="save-prestation left">

                                <div className='formElm full-width'>
                                    <div className='formElm-box'>
                                        <TextField disabled id="standard-basic" defaultValue={this.state.data.contractLessor} label="Dénomination du bailleur" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, contractLessor: e.target.value } })} />
                                    </div>
                                </div>
                                <div className="formElm full-width">
                                    <FormControl disabled  className='header-elm' style={{ margin: 0, width: "100%" }} variant="standard" sx={{ m: 1 }}>
                                        <InputLabel id="demo-simple-select-standard-label">RIB</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.data.rib}
                                            onChange={(e) => this.setState({ data: { ...this.state.data, rib: e.target.value } })}
                                            label="Type"
                                        >
                                            <MenuItem value={"RIB 1"}>RIB 1</MenuItem>
                                            <MenuItem value={"RIB 2"}>RIB 2</MenuItem>
                                            <MenuItem value={"RIB 3"}>RIB 3</MenuItem>
                                            <MenuItem value={"RIB 4"}>RIB 4</MenuItem>
                                            <MenuItem value={"default"}>Non renseigner</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="formElm full-width">
                                    <FormControl disabled  className='header-elm' style={{ margin: 0, width: "100%" }} variant="standard" sx={{ m: 1 }}>
                                        <InputLabel id="demo-simple-select-standard-label">Signature</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.data.signature}
                                            onChange={(e) => this.setState({ data: { ...this.state.data, signature: e.target.value } })}
                                            label="Type"
                                        >
                                            <MenuItem value={"Signature 1"}>Signature 1</MenuItem>
                                            <MenuItem value={"Signature 2"}>Signature 2</MenuItem>
                                            <MenuItem value={"Signature 3"}>Signature 3</MenuItem>
                                            <MenuItem value={"Signature 4"}>Signature 4</MenuItem>
                                            <MenuItem value={"default"}>Non renseigner</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='formElm next'>
                                    <div className='formElm-box'>
                                        <TextField disabled
                                            id="standard-basic" defaultValue={this.state.data.rent}
                                            error={this.state.error.rent}
                                            helperText={this.state.error.rent ? "Oups... Caractère interdit" : ""}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">€</InputAdornment>
                                            }}
                                            label="Loyer min /mois"
                                            
                                            variant="standard"
                                            onChange={(e) => this.setState({ data: { ...this.state.data, rent: e.target.value } }, () => { this.onDataChange("rent") })} />
                                    </div>
                                    <div className='formElm-box'>
                                        <TextField disabled
                                            id="standard-basic"
                                            defaultValue={this.state.data.charges}
                                            error={this.state.error.charges}
                                            helperText={this.state.error.charges ? "Oups... Caractère interdit" : ""}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">€</InputAdornment>
                                            }}
                                            label="Charges"
                                            
                                            variant="standard"
                                            onChange={(e) => this.setState({ data: { ...this.state.data, charges: e.target.value } }, () => { this.onDataChange("charges") })} />
                                    </div>
                                </div>
                                <div className='formElm next'>
                                    <div className='formElm-box'>
                                        <TextField disabled
                                            id="standard-basic"
                                            defaultValue={this.state.data.touristTaxe}
                                            error={this.state.error.touristTaxe}
                                            helperText={this.state.error.touristTaxe ? "Oups... Caractère interdit" : ""}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">€</InputAdornment>
                                            }}
                                            label="Taxe de séjour"
                                             variant="standard"
                                            onChange={(e) => this.setState({ data: { ...this.state.data, touristTaxe: e.target.value } }, () => { this.onDataChange("touristTaxe") })} />
                                    </div>
                                    <div className='formElm-box'>
                                        <TextField disabled
                                            id="standard-basic"
                                            defaultValue={this.state.data.guarantee}
                                            error={this.state.error.guarantee}
                                            helperText={this.state.error.guarantee ? "Oups... Caractère interdit" : ""}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">€</InputAdornment>
                                            }}
                                            label="Caution minimum"
                                            
                                            variant="standard"
                                            onChange={(e) => this.setState({ data: { ...this.state.data, guarantee: e.target.value } }, () => { this.onDataChange("guarantee") })} />
                                    </div>
                                </div>
                            </div>
                            <div className='save-prestation right'>
                                <div className='formElm'>
                                    <div className='formElm-box'>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Ménage fin de séjours")} name="Ménage fin de séjours" className='checkbox' onChange={() => this.handleCheckBox('Ménage fin de séjours')} type="checkbox" />
                                            <label htmlFor="Ménage fin de séjours">Ménage fin de séjours</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Location de linge de lit")} name="Location de linge de lit" className='checkbox' onChange={() => this.handleCheckBox('Location de linge de lit')} type="checkbox" />
                                            <label htmlFor="Location de linge de lit">Location de linge de lit</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Piscine")} name="Piscine" className='checkbox' onChange={() => this.handleCheckBox('Piscine')} type="checkbox" />
                                            <label htmlFor="Piscine">Piscine</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Espace bien-être")} name="Espace bien-être" className='checkbox' onChange={() => this.handleCheckBox('Espace bien-être')} type="checkbox" />
                                            <label htmlFor="Espace bien-être">Espace bien-être</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Local à vélos")} name="Local à vélos" className='checkbox' onChange={() => this.handleCheckBox('Local à vélos')} type="checkbox" />
                                            <label htmlFor="Local à vélos">Local à vélos</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Location VEA/VTC/VTT")} name="Location VEA/VTC/VTT" className='checkbox' onChange={() => this.handleCheckBox('Location VEA/VTC/VTT')} type="checkbox" />
                                            <label htmlFor="Location VEA/VTC/VTT">Location VEA/VTC/VTT</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Service de restauration")} name="Service de restauration" className='checkbox' onChange={() => this.handleCheckBox('Service de restauration')} type="checkbox" />
                                            <label htmlFor="Service de restauration">Service de restauration</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Parking")} name="Parking" className='checkbox' onChange={() => this.handleCheckBox('Parking')} type="checkbox" />
                                            <label htmlFor="Parking">Parking</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Distributeur de boissons")} name="Distributeur de boissons" className='checkbox' onChange={() => this.handleCheckBox("Distributeur de boissons")} type="checkbox" />
                                            <label htmlFor="Distributeur de boissons">Distributeur de boissons</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Garderie")} name="Garderie" className='checkbox' onChange={() => this.handleCheckBox("Garderie")} type="checkbox" />
                                            <label htmlFor="Garderie">Garderie</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Salle de sports")} name="Salle de sports" className='checkbox' onChange={() => this.handleCheckBox("Salle de sports")} type="checkbox" />
                                            <label htmlFor="Salle de sports">Salle de sports</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="formElm">
                                    <div className='formElm-box'>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Wifi")} name="Wifi" className='checkbox' onChange={() => this.handleCheckBox('Wifi')} type="checkbox" />
                                            <label htmlFor="Wifi">Wifi</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Télévision")} name="Télévision" className='checkbox' onChange={() => this.handleCheckBox("Télévision")} type="checkbox" />
                                            <label htmlFor="Télévision">Télévision</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Réception")} name="Réception" className='checkbox' onChange={() => this.handleCheckBox("Réception")} type="checkbox" />
                                            <label htmlFor="Réception">Réception</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Lit(s) bébé(s)")} name="Lit(s) bébé(s)" className='checkbox' onChange={() => this.handleCheckBox("Lit(s) bébé(s)")} type="checkbox" />
                                            <label htmlFor="Lit(s) bébé(s)">Lit(s) bébé(s)</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Accès de nuit")} name="Accès de nuit" className='checkbox' onChange={() => this.handleCheckBox("Accès de nuit")} type="checkbox" />
                                            <label htmlFor="Accès de nuit">Accès de nuit</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Équipement de plein air")} name="Équipement de plein air" className='checkbox' onChange={() => this.handleCheckBox("Équipement de plein air")} type="checkbox" />
                                            <label htmlFor="Équipement de plein air">Équipement de plein air</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Ménage quotidient")} name="Ménage quotidient" className='checkbox' onChange={() => this.handleCheckBox("Ménage quotidient")} type="checkbox" />
                                            <label htmlFor="Ménage quotidient">Ménage quotidient</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Laverie")} name="Laverie" className='checkbox' onChange={() => this.handleCheckBox("Laverie")} type="checkbox" />
                                            <label htmlFor="Laverie">Laverie</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Consigne")} name="Consigne" className='checkbox' onChange={() => this.handleCheckBox("Consigne")} type="checkbox" />
                                            <label htmlFor="Consigne">Consigne</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Locals à skis")} name="Locals à skis" className='checkbox' onChange={() => this.handleCheckBox("Locals à skis")} type="checkbox" />
                                            <label htmlFor="Locals à skis">Locals à skis</label>
                                        </div>
                                        <div className='checkbox-box'>
                                            <input checked={this.state.data.prestations.includes("Salle d'arcade")} name="Salle d'arcade" className='checkbox' onChange={() => this.handleCheckBox("Salle d'arcade")} type="checkbox" />
                                            <label htmlFor="Salle d'arcade">Salle d'arcade</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div className='btnBox'>
                            <Button onClick={() => this.handleDelete()} className="neutral alert" text="Supprimer" bold={true} />
                            <Button onClick={() => this.props.onClose()} className="background-alert" text="Annuler" />
                            <Button onClick={() => this.handleModify()} text="Modifier" />
                            {!this.state.prestationPage && <Button className="info outline neutral" onClick={() => this.changePage()} text="Suivant" />}
                            {this.state.prestationPage && <Button className="info outline neutral" onClick={() => this.changePage()} text="Précédent" />}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReadHousing