import React from 'react'
import './DialogBox.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import Button from '../../components/Button/Button'

class DialogBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            //
        }
    }

    render() {
        return (
            <div id="DialogBox">
                <div id='fond' />
                <div id='DialogBox-content'>
                    <div className={`toast-border-${this.props.type}`} id='border-left' />
                    <div id='content'>
                        {this.props.type === 'info' && <FontAwesomeIcon className={`toast-icon-${this.props.type}`} icon={faInfo} />}
                        {this.props.type === 'success' && <FontAwesomeIcon className={`toast-icon-${this.props.type}`} icon={faCheck} />}
                        {this.props.type === 'alert' && <FontAwesomeIcon className={`toast-icon-${this.props.type}`} icon={faExclamation} />}
                        {this.props.type === 'error' && <FontAwesomeIcon className={`toast-icon-${this.props.type}`} icon={faTriangleExclamation} />}
                        <p>{this.props.text}</p>
                    </div>
                    <div id='border-right'>
                        <Button text="Annuler" bold={true} className="neutral" onClick={() => this.props.onClose()} />
                        <Button onClick={() => this.props.onConfirme()} className={`background-${this.props.type}`} text={this.props.btnText} />
                    </div>
                </div>
            </div>
        )
    }
}

export default DialogBox