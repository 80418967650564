import axios from 'axios';
import Config from '../config.json'

/**
 * It makes a GET request to the URL http://localhost:8080/api/housings and returns the response
 * @returns A promise
 */
async function getAllHousings(id) {
    return axios.get(`${Config.BASE_URL}/api/housings/${id}`, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

/**
 * It returns a promise that will resolve to the data of the housing with the given id
 * @param id - The id of the housing you want to get.
 * @returns The housing with the id of the parameter passed in.
 */
async function getOneHousing(id) {
    return axios.get(`${Config.BASE_URL}/api/housings/one/${id}`, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

/**
 * It returns a promise that will resolve to an array of objects that contain the name and id of all
 * the housings in the database
 * @returns An array of objects 
 */
async function getAllHousingsAutocomplete(id) {
    return axios.get(`${Config.BASE_URL}/api/housings/autocomplete/${id}`, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

/**
 * It returns a promise that will resolve to the response of the request
 * @param name - the name of the housing
 * @returns The housing with the name that was passed in.
 */
async function searchHousings(id, data) {
    return axios.post(`${Config.BASE_URL}/api/housings/search/${id}`, data, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

/**
 * It sends a POST request to the server with the data passed as a parameter
 * @param data - The data to be sent to the server, it is converted to JSON.
 * @returns The data that is being returned is the data that is being sent to the server.
 */
async function saveHousing(data) {
    return axios.post(`${Config.BASE_URL}/api/housings`, data, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

/**
 * It takes an id and data as parameters, and returns a promise that will resolve to the result of an
 * HTTP PUT request to the URL Config.BASE_URL/api/housings/ with the data as the body of
 * the request
 * @param id - the id of the housing to modify
 * @param data - the data to be sent to the server
 * @returns The data of the housing with the id passed in the url.
 */
async function modifyHousing(id, data) {
    return axios.put(`${Config.BASE_URL}/api/housings/${id}`, data, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

/**
 * It deletes a housing from the database
 * @param id - the id of the housing to delete
 * @returns The promise of the axios.delete request.
 */
async function deleteHousing(id) {
    return axios.delete(`${Config.BASE_URL}/api/housings/${id}`, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        }
    })
}

/**
 * It deletes many housing records from the database
 * @param data - {
 */
async function deleteManyHousing(data) {
    return axios.delete(`${Config.BASE_URL}/api/housings`, {
        headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem("token")
        },
        data: data
    })
}

export const housingsService = {
    getAllHousings,
    getOneHousing,
    getAllHousingsAutocomplete,
    searchHousings,
    saveHousing,
    modifyHousing,
    deleteHousing,
    deleteManyHousing
}

