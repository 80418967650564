import React from 'react'
import './Login.css'
import { TextField } from '@mui/material'
import Button from '../../components/Button/Button'
import Loader from '../../components/Loader/Loader'
import { useNavigate } from 'react-router-dom'
import { usersService } from '../../services/users.service'
import { Player } from '@lottiefiles/react-lottie-player';
import logo from '../../assets/images/logo.svg'
import illustration from '../../assets/images/login.svg'
import ButtonLink from '../../components/ButtonLink/ButtonLink'

function withParams(Component) {
    return props => <Component {...props} navigate={useNavigate()} />;
}
class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loginData: {},
            error: false,
            showPassword: false,
            isLoading: false
        }

        this.login = this.login.bind(this)
    }

    componentDidMount() {
        document.getElementById('sidebar').style.display = 'none'
        sessionStorage.clear()
    }

    login() {
        this.setState({isLoading: true})
        usersService.login(this.state.loginData)
            .then((response) => {
                this.setState({
                    error: false,
                })
                this.props.getUserToken({
                    token: response.data.token
                })
                sessionStorage.setItem('token', response.data.token)
                setTimeout(() => {
                    this.props.navigate('/')
                }, 500);
            })
            .catch((error) => {
                this.setState({
                    error: true,
                    isLoading: false
                })
            })
    }

    render() {
        return (
            <div id='login'>
                <div id='illustration'>
                    <img src={illustration} alt="" />
                </div>

                {this.state.isLoading && <div id="form"><Loader/></div>}
                {!this.state.isLoading && <div id="form">
                    <img src={logo} alt="" />
                    <div className="title">
                        <h2>Se connecter</h2>
                        <p>Acceder à votre espace personnel.</p>
                    </div>
                    <div id='login-form'>
                        <h2>Connexion test de prod</h2>
                        <TextField defaultValue={""} data-label="email" error={this.state.error} label="Email" variant="standard" onChange={(e) => this.setState({ loginData: { ...this.state.loginData, email: e.target.value } })} />
                        <TextField defaultValue={""} data-label="password" type="password" error={this.state.error} label="password" variant="standard" onChange={(e) => this.setState({ loginData: { ...this.state.loginData, password: e.target.value } })} />
                        <Button onClick={() => this.login()} text="Se connecter" />
                        <div id='aux'>
                            <p>Pas de compte ?
                                <ButtonLink to='/signup' className={`alert outline neutral`} text="Créer un compte gratuit !" />
                            </p>
                            <p>Mot de passe oublié</p>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}

export default withParams(Login)

