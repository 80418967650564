import * as React from 'react';
import './table.css'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Rating, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Loader from '../Loader/Loader';
import EmptyState from '../EmptyState/EmptyState';


class BaseTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 15
        }

        this.getInfos = this.getInfos.bind(this)
    }

    getInfos(infos) {
        this.props.getInfos(infos)
    }

    formatePhone(phone) {
        if (phone === undefined) {
            return ""
        }
        let newphone = phone
        let phoneList = newphone.split("")
        return `${phoneList[0]}${phoneList[1]} ${phoneList[2]}${phoneList[3]} ${phoneList[4]}${phoneList[5]} ${phoneList[6]}${phoneList[7]} ${phoneList[8]}${phoneList[9]}`
    }

    render() {

        const { columns, data, height=0.75 } = this.props

        const handleChangePage = (event, newPage) => {
            this.setState({
                page: newPage
            })
        };

        const handleChangeRowsPerPage = (event) => {
            this.setState({
                rowsPerPage: +event.target.value,
                page: 0
            })
        };

        let windowHeight = window.innerHeight

        return (
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer className='position-relative' sx={{ maxHeight: windowHeight * height, minHeight: windowHeight * height }}>
                    {this.props.isLoading && <Loader />}
                    {this.props.emptyState.showEmptyState && <EmptyState type={this.props.emptyState.type} text={this.props.emptyState.text} />}
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <th scope="col" className='MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-sizeMedium css-y8ay40-MuiTableCell-root checkbox-input-header'>
                                    {this.props.showDeleteBtn && <FontAwesomeIcon onClick={() => this.props.handleManyDelete()} icon={faTrash} />}
                                    {!this.props.showDeleteBtn && <span><div id='placeholder' /></span>}
                                </th>
                                {columns.map((column) => {
                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            onClick={() => column.sort === true ? this.props.sort({ column: column.id, type: column.type, ad: column.ad === 'asc' ? 'dsc' : 'asc' }) : ""}
                                        >
                                            <p>{column.label} {column.sort === true ? <FontAwesomeIcon id={`${column.id}-sort`} icon={faCaretDown} /> : ""}</p>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!this.props.emptyState.showEmptyState && data
                                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            <th className='checkbox-input'>
                                                <input className='checkbox' onChange={() => this.props.handleCheckBox(row._id)} type="checkbox" />
                                            </th>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell style={{color: column.id === "status" ? value === "Disponible" ? "#5ab249" : value === "Indisponible" ? "#f00000" : value === "En travaux" ? "#f15a24" : "" : ""}} key={column.id} align={column.align} onClick={() => this.getInfos(row)}>
                                                        {column.id !== "rating" && column.id !== "phone" && (column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value)}
                                                        {column.id === "phone" && (column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : this.formatePhone(value))}
                                                        {column.id === 'rating' && <Stack spacing={1}>
                                                            <Rating id="note" name="half-rating" readOnly value={undefined || value} precision={0.5} />
                                                        </Stack>}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        )
    }
}

export default BaseTable