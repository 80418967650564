import axios from 'axios';
import Config from '../config.json'

async function signup(data) {
    return axios.post(`${Config.BASE_URL}/api/auth/signup`, data)
}

async function login(data) {
    return axios.post(`${Config.BASE_URL}/api/auth/login`, data)
}

async function verify(data) {
    return axios.post(`${Config.BASE_URL}/api/auth/verify`, data)
}

export const usersService = {
    signup,
    login,
    verify
}

