import React from 'react'
import './ContractPages.css'
import Signature from '../../assets/images/signature.png'

class ContractPages extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            disabled: true
        }

        this.calculDay = this.calculDay.bind(this)
        this.getCheck = this.getCheck.bind(this)
    }

    componentDidMount() {
        const check = document.querySelectorAll(".check")
        if (check[0].checked && check[1].checked) {
            document.getElementById('check').style.color = 'red'
            this.props.disabled(true)
        } else if (check[0].checked || check[1].checked) {
            document.getElementById('check').style.color = 'black'
            this.props.disabled(false)
        } else {
            document.getElementById('check').style.color = 'red'
            this.props.disabled(true)
        }
    }

    calculDay() {
        let date_1 = new Date(this.props.rentalData.departDate);
        let date_2 = new Date(this.props.rentalData.arrivalDate);
        let TotalDays = 0

        let difference = date_1.getTime() - date_2.getTime();
        TotalDays = Math.ceil(difference / (1000 * 3600 * 24));

        return TotalDays;
    }

    Unite(nombre) {
        let unite;
        switch (nombre) {
            case 0: unite = "zéro"; break;
            case 1: unite = "un"; break;
            case 2: unite = "deux"; break;
            case 3: unite = "trois"; break;
            case 4: unite = "quatre"; break;
            case 5: unite = "cinq"; break;
            case 6: unite = "six"; break;
            case 7: unite = "sept"; break;
            case 8: unite = "huit"; break;
            case 9: unite = "neuf"; break;
            default:
                break;
        }//fin switch
        return unite;
    }

    Dizaine(nombre) {
        let dizaine;
        switch (nombre) {
            case 10: dizaine = "dix"; break;
            case 11: dizaine = "onze"; break;
            case 12: dizaine = "douze"; break;
            case 13: dizaine = "treize"; break;
            case 14: dizaine = "quatorze"; break;
            case 15: dizaine = "quinze"; break;
            case 16: dizaine = "seize"; break;
            case 17: dizaine = "dix-sept"; break;
            case 18: dizaine = "dix-huit"; break;
            case 19: dizaine = "dix-neuf"; break;
            case 20: dizaine = "vingt"; break;
            case 30: dizaine = "trente"; break;
            case 40: dizaine = "quarante"; break;
            case 50: dizaine = "cinquante"; break;
            case 60: dizaine = "soixante"; break;
            case 70: dizaine = "soixante-dix"; break;
            case 80: dizaine = "quatre-vingt"; break;
            case 90: dizaine = "quatre-vingt-dix"; break;
            default:
                break;
        }

        return dizaine;
    }

    NumberToLetter(nombre) {
        var i, j, n, quotient, reste, nb;
        var ch
        var numberToLetter = '';

        if (nombre.toString().replace(/ /gi, "").length > 15) return "dépassement de capacité";
        if (isNaN(nombre.toString().replace(/ /gi, ""))) return "Nombre non valide";

        nb = parseFloat(nombre.toString().replace(/ /gi, ""));
        if (Math.ceil(nb) !== nb) return "Nombre avec virgule non géré.";

        n = nb.toString().length;
        switch (n) {
            case 1: numberToLetter = this.Unite(nb); break;
            case 2: if (nb > 19) {
                quotient = Math.floor(nb / 10);
                reste = nb % 10;
                if (nb < 71 || (nb > 79 && nb < 91)) {
                    if (reste === 0) numberToLetter = this.Dizaine(quotient * 10);
                    if (reste === 1) numberToLetter = this.Dizaine(quotient * 10) + "-et-" + this.Unite(reste);
                    if (reste > 1) numberToLetter = this.Dizaine(quotient * 10) + "-" + this.Unite(reste);
                } else numberToLetter = this.Dizaine((quotient - 1) * 10) + "-" + this.Dizaine(10 + reste);
            } else numberToLetter = this.Dizaine(nb);
                break;
            case 3: quotient = Math.floor(nb / 100);
                reste = nb % 100;
                if (quotient === 1 && reste === 0) numberToLetter = "cent";
                if (quotient === 1 && reste !== 0) numberToLetter = "cent" + " " + this.NumberToLetter(reste);
                if (quotient > 1 && reste === 0) numberToLetter = this.Unite(quotient) + " cents";
                if (quotient > 1 && reste !== 0) numberToLetter = this.Unite(quotient) + " cent " + this.NumberToLetter(reste);
                break;
            case 4: quotient = Math.floor(nb / 1000);
                reste = nb - quotient * 1000;
                if (quotient === 1 && reste === 0) numberToLetter = "mille";
                if (quotient === 1 && reste !== 0) numberToLetter = "mille" + " " + this.NumberToLetter(reste);
                if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " mille";
                if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
                break;
            case 5: quotient = Math.floor(nb / 1000);
                reste = nb - quotient * 1000;
                if (quotient === 1 && reste === 0) numberToLetter = "mille";
                if (quotient === 1 && reste !== 0) numberToLetter = "mille" + " " + this.NumberToLetter(reste);
                if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " mille";
                if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
                break;
            case 6: quotient = Math.floor(nb / 1000);
                reste = nb - quotient * 1000;
                if (quotient === 1 && reste === 0) numberToLetter = "mille";
                if (quotient === 1 && reste !== 0) numberToLetter = "mille" + " " + this.NumberToLetter(reste);
                if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " mille";
                if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
                break;
            case 7: quotient = Math.floor(nb / 1000000);
                reste = nb % 1000000;
                if (quotient === 1 && reste === 0) numberToLetter = "un million";
                if (quotient === 1 && reste !== 0) numberToLetter = "un million" + " " + this.NumberToLetter(reste);
                if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " millions";
                if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
                break;
            case 8: quotient = Math.floor(nb / 1000000);
                reste = nb % 1000000;
                if (quotient === 1 && reste === 0) numberToLetter = "un million";
                if (quotient === 1 && reste !== 0) numberToLetter = "un million" + " " + this.NumberToLetter(reste);
                if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " millions";
                if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
                break;
            case 9: quotient = Math.floor(nb / 1000000);
                reste = nb % 1000000;
                if (quotient === 1 && reste === 0) numberToLetter = "un million";
                if (quotient === 1 && reste !== 0) numberToLetter = "un million" + " " + this.NumberToLetter(reste);
                if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " millions";
                if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
                break;
            case 10: quotient = Math.floor(nb / 1000000000);
                reste = nb - quotient * 1000000000;
                if (quotient === 1 && reste === 0) numberToLetter = "un milliard";
                if (quotient === 1 && reste !== 0) numberToLetter = "un milliard" + " " + this.NumberToLetter(reste);
                if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " milliards";
                if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
                break;
            case 11: quotient = Math.floor(nb / 1000000000);
                reste = nb - quotient * 1000000000;
                if (quotient === 1 && reste === 0) numberToLetter = "un milliard";
                if (quotient === 1 && reste !== 0) numberToLetter = "un milliard" + " " + this.NumberToLetter(reste);
                if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " milliards";
                if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
                break;
            case 12: quotient = Math.floor(nb / 1000000000);
                reste = nb - quotient * 1000000000;
                if (quotient === 1 && reste === 0) numberToLetter = "un milliard";
                if (quotient === 1 && reste !== 0) numberToLetter = "un milliard" + " " + this.NumberToLetter(reste);
                if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " milliards";
                if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
                break;
            case 13: quotient = Math.floor(nb / 1000000000000);
                reste = nb - quotient * 1000000000000;
                if (quotient === 1 && reste === 0) numberToLetter = "un billion";
                if (quotient === 1 && reste !== 0) numberToLetter = "un billion" + " " + this.NumberToLetter(reste);
                if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " billions";
                if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
                break;
            case 14: quotient = Math.floor(nb / 1000000000000);
                reste = nb - quotient * 1000000000000;
                if (quotient === 1 && reste === 0) numberToLetter = "un billion";
                if (quotient === 1 && reste !== 0) numberToLetter = "un billion" + " " + this.NumberToLetter(reste);
                if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " billions";
                if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
                break;
            case 15: quotient = Math.floor(nb / 1000000000000);
                reste = nb - quotient * 1000000000000;
                if (quotient === 1 && reste === 0) numberToLetter = "un billion";
                if (quotient === 1 && reste !== 0) numberToLetter = "un billion" + " " + this.NumberToLetter(reste);
                if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " billions";
                if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
                break;
            default:
                break;
        }

        if (numberToLetter.substr(numberToLetter.length - "quatre-vingt".length, "quatre-vingt".length) === "quatre-vingt") numberToLetter = numberToLetter + "s";

        return numberToLetter;
    }

    getCheck() {
        const check = document.querySelectorAll(".check")
        if (check[0].checked && check[1].checked) {
            document.getElementById('check').style.color = 'red'
            this.props.disabled(true)
        } else if (check[0].checked || check[1].checked) {
            document.getElementById('check').style.color = 'black'
            this.props.disabled(false)
        } else {
            document.getElementById('check').style.color = 'red'
            this.props.disabled(true)
        }
    }

    render() {

        const { userData, tenantData, housingData, rentalData } = this.props


        this.calculDay()

        const date = new Date()
        const currentDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`

        return (
            <div id='pages-container'>
                <div className='contract-page'>
                    <div className='tlcorner' />
                    <div className='trcorner' />
                    <div className='blcorner' />
                    <div className='brcorner' />

                    <h2>CONTRAT DE BAIL SAISONNIER</h2>
                    <p>(Article 1713 du Code civil)</p>

                    <div style={{ height: "20px" }} />

                    <h3>I. Désignation des parties</h3>

                    <div style={{ height: "8px" }} />
                    <p style={{ fontWeight: "bold" }}>Le présent contrat est conclu entre les soussignés :</p>
                    <div style={{ height: "5px" }} />
                    <ul>
                        <li style={{ columnGap: "2px" }}>- Nom et prénom, ou dénomination du bailleur: <span className='input-field'>{userData.userLastname} {userData.userName}</span></li>
                        <li style={{ columnGap: "2px" }}>- <span className='noWrap'>Domicile ou siège social du bailleur:</span> <span className='input-field'>{userData.userCity}</span></li>
                        <li id='check' style={{ columnGap: "10px" }}>- Qualité du bailleur: <span className='ckeckbox-field'><input onChange={() => this.getCheck()} className='check' type="checkbox" /><label htmlFor="prsPhysique">Personne physique</label></span><span className='ckeckbox-field'><input onChange={() => this.getCheck()} className='check' type="checkbox" /><label htmlFor="prsPhysique">Personne morale</label></span></li>
                        <li style={{ columnGap: "2px" }}>- Email du bailleur: <span className='input-field'>{userData.userEmail}</span></li>
                    </ul>

                    <div style={{ height: "8px" }} />

                    <p style={{ fontWeight: "bold" }}>désigné (s) ci-après le bailleur</p>

                    <div style={{ height: "5px" }} />
                    <ul>
                        <li style={{ columnGap: "2px" }}>- Nom et prénom du locataire: <span className='input-field'>{tenantData.lastname} {tenantData.name}</span></li>
                        <li style={{ columnGap: "2px" }}>- <span className='noWrap'>Adresse du locataire:</span> <span className='input-field'>{tenantData.address}, {tenantData.zipcode} {tenantData.city}</span></li>
                        <li style={{ columnGap: "2px" }}>- Date de naissance: <span className='input-field'>{tenantData?.dateOfBirth?.split("-").reverse().join(" / ")}</span></li>
                        <li style={{ columnGap: "2px" }}>- Téléphone: <span className='input-field'>{tenantData.phone}</span></li>
                        <li style={{ columnGap: "2px" }}>- Email du locataire: <span className='input-field'>{tenantData.email}</span></li>
                    </ul>

                    <div style={{ height: "8px" }} />

                    <p style={{ fontWeight: "bold" }}>désigné ci-après le locataire</p>

                    <div style={{ height: "20px" }} />

                    <h3>II. Objet du contrat</h3>

                    <div style={{ height: "8px" }} />
                    <p>
                        Les parties conviennent que la location faisant l'objet des présentes est une location saisonnière, dont la durée ne peut excéder 90 jours. Le Bailleur déclare être propriétaire du logement et en avoir la libre disposition et la pleine jouissance durant la période de location définie dans les présentes. Le Bailleur pourra justifier de la propriété de son bien en fournissant les justificatifs demandés par le Preneur.
                    </p>

                    <div style={{ height: "20px" }} />

                    <h3>III. Description du logement</h3>

                    <div style={{ height: "8px" }} />

                    <p style={{ fontWeight: 'bold' }}>Le logement faisant l'objet des présentes est un appartement situé à </p>

                    <div style={{ height: "8px" }} />

                    <ul>
                        <li className='noWrap' style={{ columnGap: "2px" }}><span className='input-field'>{housingData.address}</span></li>
                        <li style={{ columnGap: "2px" }}><span className='noWrap'>{housingData.zipcode} {housingData.city}</span></li>
                    </ul>

                    <div style={{ height: "20px" }} />

                    <p style={{ fontWeight: 'bold' }}>Il a été convenu et arrêté ce qui suit :</p>

                    <div style={{ height: "8px" }} />
                    <ul>
                        <li style={{ columnGap: "2px" }}>- Surface habitable : <span className='input-field'>{housingData.surface}</span>m²</li>
                        <li style={{ columnGap: "2px" }}>- <span className='noWrap'>Composition du logement :</span> <span className='input-field'>{housingData.nbOfRooms}</span> pièces</li>
                        <li style={{ columnGap: "2px" }}>- Jeu de clé(s):<span className='input-field'>{housingData.nbOfKey}</span></li>
                        <li style={{ columnGap: "2px" }}>- Mesure(s) à respecter: <span className='input-field'>{housingData?.rules?.join(", ")}</span></li>
                    </ul>

                    <div style={{ height: "20px" }} />

                    <h3>IV. Nombre d'occupants</h3>

                    <div style={{ height: "8px" }} />
                    <p>
                        Le bien est loué pour {housingData.tenantsMaxNb} occupants maximum. Le Preneur s'engage expressément à ne pas dépasser ce nombre sans autorisation du propriétaire.
                    </p>

                    <div style={{ height: "20px" }} />

                    <h3>V. Date de prise d'effet et de durée du contrat</h3>

                    <div style={{ height: "8px" }} />
                    <p style={{ fontWeight: "bold" }}>La durée du contrat et sa date de prise d’effet sont ainsi définies :</p>
                    <div style={{ height: "5px" }} />

                    <div style={{ height: "5px" }} />
                    <p style={{ fontWeight: "bold", color: "#1b1464", fontSize: "14px" }}>A. Prise d’effet du contrat</p>
                    <div style={{ height: "5px" }} />

                    <ul>
                        <li style={{ columnGap: "2px" }}>- Date de prise d’effet du contrat : {rentalData.arrivalDate.split("-").reverse().join(" / ")}</li>
                    </ul>

                    <div style={{ height: "8px" }} />
                    <p style={{ fontWeight: "bold", color: "#1b1464", fontSize: "14px" }}>B. Durée du contrat :</p>
                    <div style={{ height: "5px" }} />

                    <p>
                        La présente location est consentie pour une durée de {this.calculDay()} jours à compter <strong>du {rentalData.arrivalDate.split("-").reverse().join(" / ")} à 16:00 heures pour se terminer le {rentalData.departDate.split("-").reverse().join(" / ")} à 10:00 heures</strong>, date et heure à laquelle le Preneur s'engage à avoir intégralement  libéré le logement.
                    </p>
                    <div style={{ height: "5px" }} />
                    <p>
                        Le bail cesse de plein droit à l’expiration de ce terme sans qu’il soit nécessaire pour le bailleur de notifier le congé. Elle ne
                        pourra être prorogée sans l’accord préalable du bailleur ou de son représentant.
                    </p>

                    <div style={{ height: "20px" }} />

                    <h3>VIII. Remise des clés</h3>

                    <div style={{ height: "8px" }} />
                    <p style={{ fontWeight: "bold", color: "#1b1464", fontSize: "14px" }}>A. Remise des clés</p>
                    <div style={{ height: "5px" }} />
                    <p>
                        La remise des clés du logement se fera {rentalData.keysDelivery}. Il en devient alors responsable. La remise des clés correspond à la prise en charge du logement par le locataire, et du debut de ses obligations (obligations de paiement du loyer / des charges / assurance du logement / etc…) En cas de perte ou de vol, le locataire devra s'acquitter des frais de serrurerie engendrés.
                    </p>

                    <div style={{ height: "8px" }} />
                    <p style={{ fontWeight: "bold", color: "#1b1464", fontSize: "14px" }}>B. Rendu des clés</p>
                    <div style={{ height: "5px" }} />
                    <p>
                        Le rendu des clé du logement ce fera {rentalData.keysReturn}. Le locataire doit restituer toutes les clés qui lui ont été remises au début du contrat et figurant sur l’état des lieux d’entrée, y compris les clés de boîtes aux lettres ou d’annexes (garage, cave, etc.). le locataire qui ne rend pas les clés à l’issue du bail reste tenu de ses obligations, responsable des éventuels dégâts qui pourraient arriver au logement et redevable des charges locatives ainsi que d’une indemnité d’occupation. <br /><br />
                        En revanche, si le bailleur est responsable d’une non-remise ou d’une restitution tardive des clés (parce qu’il n’a pas répondu à un rendez-vous de remise, par exemple), ces obligations ne s’appliquent pas.
                    </p>


                    <div style={{ height: "20px" }} />

                </div>

                <div style={{ height: "15px" }} />

                <div className='contract-page'>
                    <div className='tlcorner' />
                    <div className='trcorner' />
                    <div className='blcorner' />
                    <div className='brcorner' />
                    <div style={{ height: "20px" }} />

                    <h3>IV. Tarifs de la location et charges</h3>

                    <div style={{ height: "8px" }} />
                    <p>
                        Les Parties ont convenu de fixer le loyer à <strong>{housingData.rent}€ ({this.NumberToLetter(parseInt(housingData.rent))} euros)</strong> pour l’intégralité de la durée de la location sois <strong>{this.calculDay() - 1} nuits</strong>.
                    </p>
                    <div style={{ height: "5px" }} />
                    <p>
                        Le loyer ci-dessus comprend, pour toute la durée de la location, le paiement des charges locatives et des fournitures
                        disponibles décrites dans l’état descriptif des lieux loués attachés au contrat.
                    </p>
                    <div style={{ height: "20px" }} />

                    <h3>V. Réservation</h3>

                    <div style={{ height: "8px" }} />

                    <p>
                        Afin de procéder à la réservation du logement, le Preneur retourne au Bailleur le présent contrat paraphé à chaque page et signé accompagné du versement par VIREMENT* d’un acompte de 50 % du montant de la location soit : <strong>695 euros</strong>, le solde soit : 695 euros sera versé au plus tard 15 jours avant l’arrivée soit le : <strong>03 février 2023</strong> faute de quoi la réservation sera annulée et l’acompte sera conservé par le bailleur. Le preneur se réserve le droit de régler l’intégralité de la réservation à la signature du contrat s’il le souhaite.
                    </p>

                    <div style={{ height: "8px" }} />

                    <p style={{ fontWeight: "bold" }}>
                        * Virement sur le compte référencé ci-dessous :
                    </p>

                    <div style={{ height: "5px" }} />

                    <ul>
                        <li>Titulaire du compte : Nom du titulaire</li>
                        <li>IBAN : **** **** **** **** **** **** ***</li>
                        <li>BIc : XXXXXXXX</li>
                    </ul>

                    <div style={{ height: "20px" }} />

                    <h3>VI. Garanties</h3>

                    <div style={{ height: "8px" }} />

                    <p>
                        Afin de valider la réservation et assurer le propriétaire sur la bonne restitution de l’appartement dans un état propre et sans détériorations, <strong>le preneur s’engage à verser la somme de {housingData.guarantee}€ (en toutes lettres : {this.NumberToLetter(parseInt(housingData.guarantee))} euros) par virement* avant son arrivée ou à son arrivée en espèce à titre de dépôt de garantie</strong>  destiné à couvrir les éventuels dommages locatifs (Les chèques ne seront pas acceptés). Sont compris comme dommages locatifs, tous dommages, dégradations du logement, ainsi que les dommages, pertes ou vols causés aux biens mobiliers garnissant l'hébergement, pendant la période de location. <strong>Le dépôt de garantie par virement devra être reçu au plus tard la veille de l’arrivée</strong>.
                        En l'absence de dommages locatifs le dépôt de garantie sera restitué au Preneur immédiatement à son départ en cas de versement d’espèces. En cas de virement le dépôt de garantie sera reversé dans un délai maximum de 1 mois après son départ.
                        Dans le cas de l'existence de dommages locatifs, le dépôt de garantie sera remboursé dans un délai maximum de 2 mois, les dépenses effectuées en réparation du préjudice subi déduites, justificatifs et factures à l'appui.

                    </p>

                    <div style={{ height: "20px" }} />

                    <h3>VI. Cession et sous-location</h3>

                    <div style={{ height: "8px" }} />

                    <p>
                        Le présent contrat de location saisonnière est conclu au profit du seul Preneur signataire des présentes. La cession du bail, sous-location totale ou partielle, sont rigoureusement interdites.
                    </p>

                    <div style={{ height: "20px" }} />

                    <h3>VII. État des lieux - inventaire</h3>

                    <div style={{ height: "8px" }} />
                    {/* <p style={{ fontWeight: "bold", color: "#1b1464", fontSize: "14px" }}>A. État des lieux d’entrée</p>
                    <div style={{ height: "5px" }} /> */}
                    <p>
                        Un état des lieux sera fait en présence du bailleur ou de son représentant. En cas d’absence de ce dernier, un état des lieux dûment complété sera mis à disposition du Preneur à son arrivée. Il aura alors 48 heures pour faire des contestations éventuelles, par sms ou courriel auprès du bailleur. A défaut de contestation par le Preneur dans un délai de 48 heures, l'état des lieux établi par le Bailleur sera réputé accepté par le Preneur. Le Preneur s’engage à prévenir immédiatement le propriétaire ou son représentant en cas de « casse » ou de détérioration accidentelle durant le séjour afin que ce dernier puisse remplacer ou réparer au plus tôt. Le Bailleur pourra contester l’état des lieux de sortie dans un délai courant jusqu'à l'arrivée du prochain locataire, dans une limite de 48 heures.
                    </p>

                    <div style={{ height: "20px" }} />

                    <h3>VII. Obligation du preneur</h3>

                    <div style={{ height: "8px" }} />
                    <p>
                        Le Preneur fera un usage paisible du logement loué. Il entretiendra le logement loué et le rendra en bon état de propreté. Il devra respecter le voisinage, ainsi que le règlement intérieur de la résidence. Il s'engage à faire un usage normal et raisonnable des moyens de confort (chauffage, eau, etc.), ainsi que des équipements (électroménager, multimédia, cuisine, etc.) mis à sa disposition. Il lui est interdit de faire une copie des clés remises par le Bailleur. Il s'engage à informer le Bailleur dans les meilleurs délais de toute panne, dommage, incidents, ou dysfonctionnement. Le Preneur est responsable de l'utilisation de l'accès internet mis à sa disposition au cours de la période de location (si accès internet).

                    </p>


                    <div style={{ height: "20px" }} />

                    <h3>V. Conditions générales</h3>

                    <div style={{ height: "8px" }} />
                    <p style={{ fontWeight: "bold" }}>
                        La présente location est faite aux charges et conditions suivantes que le locataire s’oblige à exécuter et accomplir,
                        à savoir :
                    </p>

                    <div style={{ height: "5px" }} />
                    <ul className='full'>
                        <li>
                            N’occuper les lieux que bourgeoisement, l’exercice de tout commerce, profession ou industrie étant formellement interdit,
                            le locataire reconnaissant que les locaux faisant l’objet du présent contrat ne lui sont loués qu’à titre de résidence provisoire
                            et de plaisance
                        </li>
                        <li>
                            Respecter la capacité d’accueil de l’habitation</li>
                        <li>
                            Respecter la destination de l’habitation et à n’apporter aucune modification d’agencement des meubles et des lieux</li>

                        <li>
                            Ne pouvoir se substituer quelque personne que ce soit, ni sous-louer, en totalité ou partiellement, même gratuitement les
                            lieux loués, sauf accord écrit du bailleur</li>
                        <li>
                            Être assuré contre les risques locatifs, vol, incendie, dégâts des eaux et les recours des voisins et faire assurer le mobilier
                            donné en location (soit à travers son propre contrat d’assurance couvrant les risques de la location saisonnière, soit en
                            souscrivant une police d’assurance particulière pour toute la durée de la location)
                        </li>
                        <li>
                            S’abstenir de jeter dans les lavabos, baignoires, bidets, éviers des objets de nature à obstruer les canalisations, faute de
                            quoi il sera redevable des frais occasionnés pour la remise en service de ces équipements
                        </li>
                        <li>
                            Effectuer toute réclamation concernant les installations dans les 48 h suivant l’entrée dans le logement. Dans le cas
                            contraire, elle ne pourra être admise
                        </li>
                    </ul>
                </div>

                <div style={{ height: "15px" }} />

                <div className='contract-page'>
                    <div className='tlcorner' />
                    <div className='trcorner' />
                    <div className='blcorner' />
                    <div className='brcorner' />

                    <div style={{ height: "20px" }} />
                    <ul className='full'>
                        <li>
                            Avertir le bailleur dans les plus brefs délais de tout dégât affectant l’habitation, son mobilier ou ses équipements.
                            Les réparations rendues nécessaires par la négligence ou le mauvais entretien en cours de location, seront à la charge du
                            locataire
                        </li>
                        <li>
                            Autoriser le Bailleur, ou tout tiers mandaté par lui à cet effet, à effectuer, pendant la durée de la location, toute
                            réparation commandée par l’urgence. Le Locataire ne pourra réclamer aucune réduction de loyer au cas où des réparations
                            urgentes incombant au bailleur apparaîtraient en cours de location
                        </li>
                        <li>
                            Eviter tout bruit ou comportement, de son fait, du fait de sa famille ou du fait de ses relations, de nature à troubler les
                            voisins
                        </li>
                        <li>
                            Respecter, en cas de location dans un immeuble collectif, le règlement de copropriété et/ou règlement intérieur de l’immeuble qui lui aura été communiqué par le bailleur, son mandataire ou par voie d’affichage
                        </li>
                        <li>
                            Accepter la visite des locaux si le bailleur ou son mandataire en font la demande
                        </li>
                        <li>
                            Renoncer à tout recours contre le bailleur en cas de vol et déprédations dans les lieux loués
                        </li>
                        <li className='last-item'>
                            Entretenir le logement loué et le restituer en bon état de propreté et de réparations locatives en fin de location. Si des
                            objets figurant à l’inventaire sont endommagés, le bailleur pourra réclamer leur valeur de remplacement.
                        </li>
                    </ul>

                    <div style={{ height: "20px" }} />

                    <h3>IX. Annulation</h3>

                    <div style={{ height: "8px" }} />
                    <p>
                        Le Preneur, s'il renonce unilatéralement à la location, abandonne toutes les sommes versées. Si le Bailleur résilie unilatéralement le contrat pour toute raison autre qu'une inexécution des obligations contractuelles du locataire, il devra rembourser les sommes versées dans les meilleurs délais s'il s'agit d'un cas de force majeure rendant la location impossible. En cas de mesures gouvernementales imposant une restriction des déplacements, l’ensemble des sommes perçues par le bailleur sera remboursé dans leur intégralité. Un test positif au COVID-19 ne justifiera pas le remboursement de la réservation.
                    </p>

                    <div style={{ height: "20px" }} />

                    <h3>IX. Assurance</h3>

                    <div style={{ height: "8px" }} />
                    <p>
                        Le Preneur indique bénéficier d'une assurance couvrant les risques locatifs. Une copie de la police d’assurance pourra être demandée par le Bailleur au Preneur lors de la réservation ou à l’entrée dans les lieux.
                    </p>

                    <div style={{ height: "20px" }} />

                    <h3>IX. Resiliation</h3>

                    <div style={{ height: "8px" }} />
                    <p>
                        En cas de manquement par le Preneur à l’une de ses obligations contractuelles, le présent bail sera résilié de plein droit. Cette résiliation prendra effet 48 heures après notification par lettre recommandée ou lettre remise en main propre restée infructueuse.
                    </p>

                    <div style={{ height: "20px" }} />

                    <h3>IX. Domicile</h3>

                    <div style={{ height: "8px" }} />
                    <p>
                        Pour l’exécution des présentes, le Bailleur et le Preneur font élection de domicile dans leurs domiciles respectifs, indiqués en entête des présentes. Toutefois, en cas de litige, le tribunal du domicile du Bailleur sera seul compétent. Le présent contrat est soumis à la loi Française.
                    </p>

                    <div style={{ height: "40px" }} />

                    <footer>
                        <div style={{ height: "20px" }} />
                        <p>Réaliser en 2 exemplaires</p>
                        <div style={{ height: "5px" }} />
                        <p contentEditable suppressContentEditableWarning>Le {currentDate} à <span >{rentalData.place}</span></p>

                        <div style={{ height: "20px" }} />
                        <div id='signature'>
                            <p id='bailleur'>
                                Signature du bailleur (ou de son mandataire,
                                le cas échéant), précédée(s) de la mention
                                « Lu et approuvé » :
                                <img src={Signature} alt="signature" />
                            </p>
                            <p>
                                Signature du locataire précédée(s) de la mention
                                « Lu et approuvé » :
                            </p>
                        </div>
                    </footer>
                </div>
            </div >
        )
    }
}

export default ContractPages