import React from 'react'
import './SaveTenants.css'
import IllustrationSave from '../../../assets/images/saveTenant.svg'
import { Rating, Stack, TextField } from '@mui/material'
import Button from '../../Button/Button'

class SaveTenant extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                name: "",
                lastname: "",
                address: "",
                city: "",
                zipcode: "",
                email: "",
                phone: "",
                dateOfBirth: "",
                rating: "",
                comment: ""
            },
            error: {
                name: false,
                lastname: false,
                zipcode: false,
                email: false,
                phone: false,
            },
            saveBtnDisabled: true,
            state: this.props.state
        }

        this.onDataChange = this.onDataChange.bind(this)
    }


    handleSave() {
        this.props.onSave(this.state.data)
    }

    onDataChange(input) {
        const emailRegex = /[A-Z0-9._%+-]+@[A-Z0-9-]+[.]+[A-Z]{2,3}/i
        const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i
        const nameRegex = /^[a-zA-Z ]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g
        const lastnameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g

        if (input === "name" && this.state.data.name !== "") {
            if (nameRegex.test(this.state.data.name)) {
                this.setState({
                    error: {
                        ...this.state.error,
                        name: false
                    }
                })
            } else {
                this.setState({
                    error: {
                        ...this.state.error,
                        name: true
                    }
                })
            }
        }

        if (input === "lastname" && this.state.data.lastname !== "") {
            if (lastnameRegex.test(this.state.data.lastname)) {
                this.setState({
                    error: {
                        ...this.state.error,
                        lastname: false
                    }
                })
            } else {
                this.setState({
                    error: {
                        ...this.state.error,
                        lastname: true
                    }
                })
            }
        }

        if (input === "email" && this.state.data.email !== "") {
            if (emailRegex.test(this.state.data.email)) {
                this.setState({
                    error: {
                        ...this.state.error,
                        email: false
                    }
                })
            } else {
                this.setState({
                    error: {
                        ...this.state.error,
                        email: true
                    }
                })
            }
        }

        if (input === "phone" && this.state.data.phone !== "") {
            if (phoneRegex.test(this.state.data.phone)) {
                this.setState({
                    error: {
                        ...this.state.error,
                        phone: false
                    }
                })
            } else {
                this.setState({
                    error: {
                        ...this.state.error,
                        phone: true
                    }
                })
            }
        }

        if ((emailRegex.test(this.state.data.email) && phoneRegex.test(this.state.data.phone) && nameRegex.test(this.state.data.name) && lastnameRegex.test(this.state.data.lastname))) {
            console.log("ok");
            this.setState({
                saveBtnDisabled: false
            })
        } else {
            this.setState({
                saveBtnDisabled: true
            })
        }
    }

    render() {
        return (
            <div id='SaveTenant'>
                <div id='fond'></div>
                <div id='popUpBox'>
                    <img src={IllustrationSave} alt="" />
                    <div id='save'>
                        <h3>Ajouter un locataire</h3>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <TextField data-label="name" id="standard-basic" error={this.state.error.name} helperText={this.state.error.name ? "Oups... Caractère interdit" : ""} label="Nom" required variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, name: e.target.value } }, () => { this.onDataChange("name") })} />
                            </div>
                            <div className='formElm-box'>
                                <TextField data-label="lastname" id="standard-basic" error={this.state.error.lastname} helperText={this.state.error.lastname ? "Oups... Caractère interdit" : ""} label="Prenom" required variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, lastname: e.target.value } }, () => { this.onDataChange("lastname") })} />
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <TextField id="standard-basic" label="Adresse" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, address: e.target.value } })} />
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <TextField id="standard-basic" label="Ville" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, city: e.target.value } })} />
                            </div>
                            <div className='formElm-box'>
                                <TextField id="standard-basic" label="Code postal" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, zipcode: e.target.value } })} />
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <TextField id="standard-basic" label="Email" error={this.state.error.email} helperText={this.state.error.email ? "Oups... Vérifiez l'email" : ""} required variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, email: e.target.value } }, () => { this.onDataChange("email") })} />
                            </div>
                            <div className='formElm-box'>
                                <TextField id="standard-basic" label="Téléphone" error={this.state.error.phone} helperText={this.state.error.phone ? "Oups... Mauvais numéro" : ""} required variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, phone: e.target.value } }, () => { this.onDataChange("phone") })} />
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-box'>
                                <label htmlFor="date">Date de naissance</label>
                                <input onChange={(e) => this.setState({ data: { ...this.state.data, dateOfBirth: e.target.value } })} type="date" name="date" id='date' />
                            </div>
                            <div className='formElm-box'>
                                <label htmlFor="note">Note</label>
                                <Stack spacing={1}>
                                    <Rating onChange={(e) => this.setState({ data: { ...this.state.data, rating: e.target.value } })} id="note" name="half-rating" defaultValue={0} precision={0.5} />
                                </Stack>
                            </div>
                        </div>
                        <div className='formElm'>
                            <div className='formElm-special'>
                                <TextField multiline fullWidth maxRows={5} id="outlined-textarea" label="Commentaires" variant="standard" onChange={(e) => this.setState({ data: { ...this.state.data, comment: e.target.value } })} />
                            </div>
                        </div>
                        <div className='btnBox'>
                            <Button onClick={() => this.props.onClose()} className="neutral alert" text="Annuler" bold={true} />
                            <Button disabled={this.state.saveBtnDisabled} onClick={() => this.handleSave()} text="Enregistrer" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SaveTenant