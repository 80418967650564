import React, { Fragment } from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { usersService } from '../../services/users.service';

class PrivateRoutes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            token: sessionStorage.getItem('token') || this.props.token,
            decoded: "spin"
        }
        this.verify = this.verify.bind(this)
    }

    componentDidMount() {
        this.verify()
    }

    verify() {
        usersService.verify({ token: this.state.token })
            .then((response) => {
                this.props.checkToken({ ...response.data.decoded })
                this.setState({
                    decoded: response.data.decoded
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    token: undefined
                })
                sessionStorage.clear()
                this.props.checkToken({})
            })
    }

    render() {
        console.log(this.state.decoded);
        return (
            this.state.token ?
                <Fragment>
                    {this.state.decoded === "spin" && <Outlet />}
                    {this.state.decoded !== "spin" && <Outlet />}
                </Fragment>
                :
                <Navigate to='/login' />
        )
    }
}

export default PrivateRoutes;