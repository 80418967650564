import React from 'react'
import './SearchBarBaseTable.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FormControl, MenuItem, Select } from '@mui/material'

class SearchBarBaseTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                key: "name",
                query: ""
            }
        }

        this.waitForStop = this.waitForStop.bind(this)
    }

    componentDidMount() {
        this.waitForStop()
    }

    waitForStop() {
        let input = document.getElementById('search-input');
        let timeout = null;
        const myThis = this

        input.addEventListener('keyup', function (e) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                myThis.props.handleSearch(myThis.state.data)
            }, 1000);
        });
    }

    formateQuery(query) {
        const baseQuery = query
        return baseQuery.charAt(0).toUpperCase() + baseQuery.slice(1).toLowerCase();
    }

    render() {
        return (
            <div id='SearchBarBaseTable'>
                <FontAwesomeIcon id='searchIcon' icon={faSearch} />
                <FormControl style={{ margin: 0, height: "100%", borderRadius: "none" }} variant="filled" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                        style={{ margin: 0, height: "100%", backgroundColor: "transparent" }}
                        labelId="demo-simple-select-standard-label"
                        id="searchSelect"
                        value={this.state.data.key}
                        onChange={(e) => this.setState({ data: { ...this.state.data, key: e.target.value } })}
                        label="Type"
                    >
                        <MenuItem value={"name"}>Nom</MenuItem>
                        <MenuItem value={"lastname"}>Prénom</MenuItem>
                    </Select>
                </FormControl>
                <input id='search-input' onChange={(e) => this.setState({ data: { ...this.state.data, query: this.formateQuery(e.target.value) } })} type="text" placeholder='Rechercher' />
            </div>
        )
    }
}

export default SearchBarBaseTable