import React from 'react'
import './Toast.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

class Toast extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            //
        }
    }

    render() {
        return (
            <div className={this.props.show ? 'show' : ''} id="toast">
                <div className={`toast-border-${this.props.type}`} id='border-left' />
                <div id='content'>
                    {this.props.type === 'info' && <FontAwesomeIcon className={`toast-icon-${this.props.type}`} icon={faInfo} />}
                    {this.props.type === 'success' && <FontAwesomeIcon className={`toast-icon-${this.props.type}`} icon={faCheck} />}
                    {this.props.type === 'alert' && <FontAwesomeIcon className={`toast-icon-${this.props.type}`} icon={faExclamation} />}
                    {this.props.type === 'error' && <FontAwesomeIcon className={`toast-icon-${this.props.type}`} icon={faTriangleExclamation} />}
                    <p>{this.props.text}</p>
                </div>
                <div id='border-right' />
            </div>
        )
    }
}

export default Toast