import React from 'react'
import './UploadHousingList.css'
import { FileUploader } from "react-drag-drop-files";
import Button from '../../Button/Button'
import Illustration from '../../../assets/images/uploadExcel.svg'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

class UploadHousingList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fileTypes: this.props.fileTypes,
            data: "",
            params: {
                type: 'Appartement',
                status: 'Indisponible'
            }
        }
        this.handleDrop = this.handleDrop.bind(this)
        this.close = this.close.bind(this)
    }

    handleDrop(file) {
        let myFile = file
        let data = new FormData();
        data.append('uploadfile', myFile);
        this.setState({
            data: data
        })
    }

    handleDragOver(state) {
        if (state === true) {
            document.querySelector('.uploadForm').style.backgroundColor = '#97979720'
            document.querySelector('.uploadForm').style.borderColor = '#979797'
        } else {
            document.querySelector('.uploadForm').style.backgroundColor = '#5ab24918'
            document.querySelector('.uploadForm').style.borderColor = '#5ab249'
        }
    }

    handleSuccessDrop() {
        let formData = this.state.data
        let file = formData.get('uploadfile')

        let statusColor = '#5ab249'
        switch (this.state.params.status) {
            case 'Indisponible':
                statusColor = '#f00000'
                break;
            case 'Disponible':
                statusColor = '#5ab249'
                break;
            case 'En travaux':
                statusColor = '#f15a24'
                break;

            default:
                statusColor = '#5ab249'
                break;
        }

        return (
            <div id='dropSuccess'>
                <img src={Illustration} alt="" />
                <div>
                    <div id='info'>
                        <h4 style={{ fontSize: "16px" }}>{file.name}</h4>
                        <p>{Math.round(file.size * 0.001)} Ko</p>
                    </div>
                    <div className='btnBox-uploadHousing-special'>
                        <FormControl style={{ margin: 0 }} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={this.state.params.type}
                                onChange={(e) => this.setState({ params: { ...this.state.params, type: e.target.value } })}
                                label="Type"
                            >
                                <MenuItem value={"Appartement"}>Appartement</MenuItem>
                                <MenuItem value={"Chateau"}>Chateau</MenuItem>
                                <MenuItem value={"Chambre étudiante"}>Chambre étudiante</MenuItem>
                                <MenuItem value={"Gîte"}>Gîte</MenuItem>
                                <MenuItem value={"Maison individuelle"}>Maison individuelle</MenuItem>
                                <MenuItem value={"Studio"}>Studio</MenuItem>
                                <MenuItem value={"Villa"}>Villa</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl style={{ margin: 0 }} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={this.state.params.status}
                                onChange={(e) => this.setState({ params: { ...this.state.params, status: e.target.value } })}
                                label="Type"
                                style={{ color: statusColor }}
                            >
                                <MenuItem style={{ color: '#5ab249' }} value={"Disponible"}>Disponible</MenuItem>
                                <MenuItem style={{ color: '#f00000' }} value={"Indisponible"}>Indisponible</MenuItem>
                                <MenuItem style={{ color: '#f15a24' }} value={"En travaux"}>En travaux</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
        )
    }

    close() {
        this.setState({
            data: ""
        })
        this.props.onClose()
    }

    render() {
        return (
            <div id='UploadHousingList'>
                <div id='fond' />
                <div id='popUpBox'>
                    <div className='title'>
                        <h3>Importer des logements</h3>
                        {/* <a download href="../../../downloads/modèles_importation_logements.xlsx">Télécharger notre modèle</a> */}
                    </div>
                    {this.state.data === "" && <FileUploader
                        label="Sélectionnez votre fichier ou glisser le ici (.xlsx) "
                        classes='uploadForm'
                        multiple={false}
                        onDraggingStateChange={(e) => { this.handleDragOver(e) }}
                        handleChange={this.handleDrop}
                        types={this.state.fileTypes}
                    />}
                    {this.state.data !== "" && this.handleSuccessDrop()}
                    <div class='btnBox-uploadHousing'>
                        <Button text="Annuler" className="neutral alert" onClick={() => this.close()} />
                        <Button disabled={this.state.data === ""} text="Importer" onClick={() => this.props.handleUpload({ data: this.state.data, params: this.state.params })} />
                    </div>
                </div>
            </div>
        )
    }
}

export default UploadHousingList
