import React from 'react';
import { Link } from 'react-router-dom'
import './homeView.css';
import logo from '../../assets/images/logo.svg'
import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import Illustration from '../../assets/images/home.svg'

class HomeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [
        {
          title: 'Mise à jour',
          message: 'Nouvelle mise à jours de Janvier'
        },
        {
          title: 'Profils incomplet',
          message: 'Il manque des informations dans votre profils, certaine fonctionnalitées peuvent ne pas êtres disponibles.'
        },
        {
          title: 'Nouvautées',
          message: 'Vous pouvez maintenant avoir accès au calendrier de vos locations !'
        },
        {
          title: 'Nouvautées',
          message: 'Vous pouvez maintenant avoir accès au calendrier de vos locations !'
        },
        {
          title: 'Nouvautées',
          message: 'Vous pouvez maintenant avoir accès au calendrier de vos locations !'
        },
        {
          title: 'Nouvautées',
          message: 'Vous pouvez maintenant avoir accès au calendrier de vos locations !'
        },
        {
          title: 'Nouvautées',
          message: 'Vous pouvez maintenant avoir accès au calendrier de vos locations !'
        },
        {
          title: 'Nouvautées',
          message: 'Vous pouvez maintenant avoir accès au calendrier de vos locations !'
        }
      ]
    }
  }

  componentDidMount() {
    document.getElementById('sidebar').style.display = 'flex'
    defineElement(lottie.loadAnimation);
  }

  render() {
    const { userData } = this.props
    return (
      <div id='home'>
        <div id='grid'>
          <div className='grid-elm'>
            <Link to="/dashboard">
              <lord-icon
                style={{ width: "80px", height: "80px" }}
                trigger="loop-on-hover"
                src="https://cdn.lordicon.com/zjscbpdr.json">

              </lord-icon>
              Tableau de bord
            </Link>
          </div>
          <div className='grid-elm'>
            <Link to="/tenants">
              <lord-icon
                style={{ width: "80px", height: "80px" }}
                trigger="loop-on-hover"
                src="https://cdn.lordicon.com/zthozvfn.json">

              </lord-icon>
              Locataires
            </Link>
          </div>
          <div className='grid-elm'>
            <Link to="/housings">
              <lord-icon
                style={{ width: "80px", height: "80px" }}
                trigger="loop-on-hover"
                src="https://cdn.lordicon.com/hjbsbdhw.json">

              </lord-icon>
              Logements
            </Link>
          </div>
          <div className='grid-elm'>
            <Link to="/contracts">
              <lord-icon
                style={{ width: "80px", height: "80px" }}
                trigger="loop-on-hover"
                src="https://cdn.lordicon.com/ckatldkn.json">

              </lord-icon>
              Contrats
            </Link>
          </div>
          <div className='grid-elm'>
            <Link to="/dashboard">
              <lord-icon
                style={{ width: "80px", height: "80px" }}
                trigger="loop-on-hover"
                src="https://cdn.lordicon.com/oegrrprk.json">

              </lord-icon>
              Analyse
            </Link>
          </div>
          <div className='grid-elm'>
            <Link to="/settings">
              <lord-icon
                style={{ width: "80px", height: "80px" }}
                trigger="loop-on-hover"
                src="https://cdn.lordicon.com/nnbhwnej.json">

              </lord-icon>
              Réglages
            </Link>
          </div>
        </div>
        <div id="content">
          <div id="title">
            <h2>Bienvenue {userData.userLastname} {userData.userName}!</h2>
            <img src={Illustration} alt="" />
          </div>
          <div id="notifications">
            <h2>Notifications <span><FontAwesomeIcon icon={faBell} /></span></h2>
            <div id="list">
              <ul>
                {this.state.notifications.map((data, i) => {
                  return (
                    <li>
                      <div className="box">
                        <p className="title">{data.title}</p>
                        <p className='message'>{data.message}</p>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <div id="copy">
          <img src={logo} alt="" />
          {/* <h2>
            Créer par <a target="_blank" rel="noreferrer" href="https://meedev.fr">Meedev</a> - {new Date().getFullYear()}
          </h2> */}
        </div>
      </div>
    )
  }
}

export default HomeView;