import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import './Page404.css';
import { NavLink } from 'react-router-dom';

class Page404 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: 'Page404'
    }
  }
  render(h) {
    return (
      <main id='page404'>
        <h1>Oups... Il semblerait que cette page n'existe pas.</h1>
        <Player
          autoplay
          loop
          src="https://assets10.lottiefiles.com/packages/lf20_2lxegjl6.json"
          style={{ width: '500px' }}
        >
        </Player>
        <NavLink className="button" to='/'>Retourner à l'accueil</NavLink>
      </main>
    )
  }
}

export default Page404;