import * as React from 'react';
import './Tenants.css'
import BaseTable from '../../components/BaseTable/BaseTable'
import Button from '../../components/Button/Button';
import ReadTenant from '../../components/TenantPopUp/ReadTenants/ReadTenants';
import SaveTenant from '../../components/TenantPopUp/SaveTenants/SaveTenants';
import ModifyTenant from '../../components/TenantPopUp/ModifyTenants/ModifyTenants';
import UploadTenantsList from '../../components/TenantPopUp/UploadTenantsList/UploadTenantsList';
import { tenantsService } from '../../services/tenants.service';
import Toast from '../../components/Toast/Toast';
import DialogBox from '../../components/DialogBox/DialogBox';
import { uploadService } from '../../services/upload.service';
import SearchBarBaseTable from '../../components/SearchBarBaseTable/SearchBarBaseTable';
import { useOutletContext } from 'react-router-dom';

function withParams(Component) {
    return props => <Component {...props} context={useOutletContext()} />;
}

class Tenants extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userData: {},
            tenantCount: 0,
            readTenant: {},
            modifyTenant: {},
            emptyState: {
                showEmptyState: false,
            },
            readTenantpopup: {
                display: false
            },
            saveTenantpopup: {
                display: false
            },
            modifyTenantpopup: {
                display: false
            },
            importTenantpopup: {
                display: false
            },
            toast: {
                display: false,
                text: '',
                type: ''
            },
            dialog: {
                display: false,
                text: 'lorem ipsum dolor amet net lotem ipsum dolor amet net',
                type: 'alert'
            },
            dialogError: {
                display: false,
                text: 'lorem ipsum dolor amet net lotem ipsum dolor amet net',
                type: 'alert'
            },
            dialogManyDelete: {
                display: false,
                text: 'lorem ipsum dolor amet net lotem ipsum dolor amet net',
                type: 'alert'
            },
            deleteUser: "",
            isLoading: false,
            columns: [
                {
                    id: 'name',
                    label: 'Nom',
                    minWidth: 170,
                    sort: true,
                    type: 'string',
                    ad: 'dsc'
                },
                {
                    id: 'lastname',
                    label: 'Prénom',
                    minWidth: 170,
                },
                {
                    id: 'email',
                    label: 'Email',
                    minWidth: 170
                },
                {
                    id: 'phone',
                    label: 'Téléphone',
                    minWidth: 100,
                },
                {
                    id: 'rating',
                    label: 'Note',
                    minWidth: 170,
                    sort: true,
                    type: 'number',
                    ad: 'dsc'
                }
            ],
            data: [],
            toDeleteList: [],
            allChecked: false
        }

        this.openReadTenantPopUp = this.openReadTenantPopUp.bind(this)
        this.openSaveTenantPopUp = this.openSaveTenantPopUp.bind(this)
        this.openImportTenantPopUp = this.openImportTenantPopUp.bind(this)
        this.openModifyTenantPopUp = this.openModifyTenantPopUp.bind(this)
        this.getAllTenants = this.getAllTenants.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleUpload = this.handleUpload.bind(this)
        this.handleModify = this.handleModify.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleManyDelete = this.handleManyDelete.bind(this)
        this.confirmDelete = this.confirmDelete.bind(this)
        this.confirmManyDelete = this.confirmManyDelete.bind(this)
        this.onClosePopup = this.onClosePopup.bind(this)
        this.onCloseDialogBox = this.onCloseDialogBox.bind(this)
        this.sortStringBaseTable = this.sortStringBaseTable.bind(this)
        this.sortNumberBaseTable = this.sortNumberBaseTable.bind(this)
        this.sortBaseTable = this.sortBaseTable.bind(this)
        this.handleCheckBox = this.handleCheckBox.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }

    componentDidMount() {
        this.getAllTenants()
    }

    getAllTenants() {
        this.setState({
            isLoading: true,
            emptyState: {
                showEmptyState: false,
            }
        })
        tenantsService.getAllTenants(this.props.userData.userId)
            .then((response => {
                this.setState({
                    isLoading: false,
                    data: [...response.data],
                    tenantCount: response.data.length,
                    emptyState: {
                        showEmptyState: response.data.length === 0 ? true : false,
                        type: "data",
                    }
                })
            }))
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    emptyState: {
                        showEmptyState: true,
                        type: error.response.status === 401 ? "unauthorized" : "error"
                    }
                })
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            })
    }

    handleSave(data) {
        this.setState({
            isLoading: true,
            emptyState: {
                showEmptyState: false,
            }
        })
        this.onClosePopup()

        const baseName = data.name;
        const newName = baseName.charAt(0).toUpperCase() + baseName.slice(1).toLowerCase();
        const baseLastName = data.lastname;
        const newLastName = baseLastName.charAt(0).toUpperCase() + baseLastName.slice(1).toLowerCase();
        const baseAddress = data.address;
        const newAdress = baseAddress.charAt(0).toUpperCase() + baseAddress.slice(1).toLowerCase();

        data.name = newName
        data.lastname = newLastName
        data.address = newAdress
        data.createdBy = this.props.userData.userId

        tenantsService.saveTenant(data)
            .then((response => {
                this.getAllTenants()
                this.setState({
                    isLoading: false,
                    toast: {
                        display: true,
                        text: 'Tenant enregistré',
                        type: 'success'
                    }
                })
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            }))
            .catch((error) => {
                this.onClosePopup()
                if (error.response.status === 400) {
                    this.getAllTenants()
                    this.setState({
                        isLoading: false,
                        dialogError: {
                            display: true,
                            text: `Oups... Il semblerai que cet email ou ce numéro de téléphone sois déjà attribué à un locataire.`,
                            type: 'error'
                        },
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        toast: {
                            display: true,
                            text: 'Probleme lors de l\'importation',
                            type: 'error'
                        },
                        emptyState: {
                            showEmptyState: true,
                            type: "error"
                        }
                    })
                    setTimeout(() => {
                        this.setState({
                            toast: {
                                display: false,
                                text: '',
                                type: ''
                            }
                        })
                    }, 5000);
                }
            })
    }

    handleUpload(data) {
        this.setState({
            isLoading: true,
            emptyState: {
                showEmptyState: false,
            }
        })
        this.onClosePopup()
        uploadService.uploadTenantList(this.props.userData.userId, data)
            .then((response) => {
                this.getAllTenants()
                this.setState({
                    isLoading: false,
                    toast: {
                        display: true,
                        text: 'Locataires importés',
                        type: 'success'
                    }
                })
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            })
            .catch((error) => {
                this.onClosePopup()
                console.log(error);
                if (error.response.status === 400) {
                    this.getAllTenants()
                    this.setState({
                        isLoading: false,
                        dialogError: {
                            display: true,
                            text: error.response.data.lignes === undefined ? `Oups... Il semblerai qu'un email ou un numéro de téléphone sois déjà attribué à un locataire.` : `Oups... Il semblerai que certaine données sois mal formater. \n Vérifier les adresses mails et les numéros de téléphone sur les lignes : ${error.response.data.lignes}`,
                            type: 'error'
                        },
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        toast: {
                            display: true,
                            text: 'Probleme lors de l\'importation',
                            type: 'error'
                        },
                        emptyState: {
                            showEmptyState: true,
                            type: "error"
                        }
                    })
                    setTimeout(() => {
                        this.setState({
                            toast: {
                                display: false,
                                text: '',
                                type: ''
                            }
                        })
                    }, 5000);
                }
            })
    };

    handleModify(data) {
        const id = data._id
        delete data.__v
        delete data._id

        this.setState({
            isLoading: true,
            emptyState: {
                showEmptyState: false,
            }
        })
        this.onClosePopup()

        tenantsService.modifyTenant(id, data)
            .then((response => {
                this.getAllTenants()
                this.setState({
                    isLoading: false,
                    toast: {
                        display: true,
                        text: 'Tenant modifié',
                        type: 'success'
                    }
                })
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            }))
            .catch((error) => {
                this.onClosePopup()
                if (error.response.status === 400) {
                    this.getAllTenants()
                    this.setState({
                        isLoading: false,
                        dialogError: {
                            display: true,
                            text: `Oups... Il semblerai que cet email ou ce numéro de téléphone sois déjà attribué à un locataire.`,
                            type: 'error'
                        },
                    })
                } else if (error.response.status === 500) {
                    this.setState({
                        isLoading: false,
                        emptyState: {
                            showEmptyState: true,
                            type: "error"
                        }
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        toast: {
                            display: true,
                            text: 'Probleme lors de l\'importation',
                            type: 'error'
                        }
                    })
                    setTimeout(() => {
                        this.setState({
                            toast: {
                                display: false,
                                text: '',
                                type: ''
                            }
                        })
                    }, 5000);
                }
            })
    }

    handleDelete(data) {
        this.setState({
            dialog: {
                display: true,
                text: `Etes vous sur de vouloir supprimer ${data.name} ${data.lastname} de vos locataires ainsi que tous ses contrats ?`,
                type: 'alert'
            },
            deleteUser: data.id
        })
        this.onClosePopup()
    }

    handleManyDelete() {
        this.setState({
            dialogManyDelete: {
                display: true,
                text: `Etes vous sur de vouloir supprimer ${this.state.toDeleteList.length} ainsi que tous leurs contrats ?`,
                type: 'alert'
            },
        })
        this.onClosePopup()
    }

    confirmDelete() {
        let id = this.state.deleteUser
        this.setState({
            isLoading: true,
            emptyState: {
                showEmptyState: false,
            }
        })
        this.onCloseDialogBox()
        tenantsService.deleteTenant(id)
            .then((response => {
                this.getAllTenants()
                this.setState({
                    isLoading: false,
                    toast: {
                        display: true,
                        text: 'Tenant supprimé',
                        type: 'success'
                    }
                })
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            }))
            .catch((error) => {
                if (error.response.status === 500) {
                    this.setState({
                        isLoading: false,
                        emptyState: {
                            showEmptyState: true,
                            type: "error"
                        }
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        toast: {
                            display: true,
                            text: 'Erreur lors de la suppression',
                            type: 'error'
                        }
                    })
                    setTimeout(() => {
                        this.onCloseDialogBox()
                        this.setState({
                            toast: {
                                display: false,
                                text: '',
                                type: ''
                            },
                            emptyState: {
                                showEmptyState: true,
                                type: "error"
                            }
                        })
                    }, 5000);
                }
            })
    }

    confirmManyDelete() {
        let data = this.state.toDeleteList
        this.setState({
            isLoading: true,
            emptyState: {
                showEmptyState: false,
            }
        })
        this.onCloseDialogBox()
        tenantsService.deleteManyTenant({ data })
            .then((response => {
                this.getAllTenants()
                this.setState({
                    isLoading: false,
                    toast: {
                        display: true,
                        text: 'Tenants supprimés',
                        type: 'success'
                    },
                    toDeleteList: []
                })
                const allCheckbox = document.querySelectorAll('.checkbox')
                allCheckbox.forEach(elm => {
                    elm.checked = false
                });
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            }))
            .catch((error) => {
                if (error.response.status === 500) {
                    this.setState({
                        isLoading: false,
                        emptyState: {
                            showEmptyState: true,
                            type: "error"
                        }
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        toast: {
                            display: true,
                            text: 'Erreur lors de la suppression',
                            type: 'error'
                        }
                    })
                    setTimeout(() => {
                        this.onCloseDialogBox()
                        this.setState({
                            toast: {
                                display: false,
                                text: '',
                                type: ''
                            },
                            emptyState: {
                                showEmptyState: true,
                                type: "error"
                            }
                        })
                    }, 5000);
                }
            })
    }

    openImportTenantPopUp() {
        this.setState({
            importTenantpopup: {
                display: true
            }
        })
    }

    openSaveTenantPopUp() {
        this.setState({
            saveTenantpopup: {
                display: true
            }
        })
    }

    openReadTenantPopUp(infos) {
        this.setState({
            readTenant: infos,
            readTenantpopup: {
                display: true
            }
        })
    }

    openModifyTenantPopUp(data) {
        this.onClosePopup()
        this.setState({
            modifyTenant: data,
            modifyTenantpopup: {
                display: true
            }
        })

    }

    onClosePopup() {
        this.setState({
            readTenant: {},
            modifyTenant: {},
            readTenantpopup: {
                display: false
            },
            saveTenantpopup: {
                display: false
            },
            modifyTenantpopup: {
                display: false
            },
            importTenantpopup: {
                display: false
            }
        })
    }

    onCloseDialogBox() {
        this.setState({
            dialog: {
                display: false,
                text: 'lorem ipsum dolor amet net lotem ipsum dolor amet net',
                type: 'info'
            },
            dialogError: {
                display: false,
                text: 'lorem ipsum dolor amet net lotem ipsum dolor amet net',
                type: 'info'
            },
            dialogManyDelete: {
                display: false,
                text: 'lorem ipsum dolor amet net lotem ipsum dolor amet net',
                type: 'info'
            },
        })
    }

    sortStringBaseTable(data, sort) {
        data.sort(function (a, b) {
            const nameA = a[sort.column].toUpperCase();
            const nameB = b[sort.column].toUpperCase();
            if (sort.ad === 'dsc') {
                document.getElementById(`${sort.column}-sort`).style.transform = "rotate(0)"
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
            } else if (sort.ad === 'asc') {
                document.getElementById(`${sort.column}-sort`).style.transform = "rotate(180deg)"
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA < nameB) {
                    return 1;
                }
            }
            return 0;
        })
    }

    sortNumberBaseTable(data, sort) {
        if (sort.ad === 'dsc') {
            data.sort((a, b) => {
                let sup = (a.rating > b.rating)
                let inf = (a.rating < b.rating) ? -1 : 0
                return sup ? 1 : inf
            })
            document.getElementById(`${sort.column}-sort`).style.transform = "rotate(0)"
        } else if (sort.ad === 'asc') {
            data.sort((a, b) => {
                let sup = (a.rating < b.rating)
                let inf = (a.rating > b.rating) ? -1 : 0
                return sup ? 1 : inf
            })
            document.getElementById(`${sort.column}-sort`).style.transform = "rotate(180deg)"
        }
    }

    sortBaseTable(sort) {
        let data = this.state.data
        let columns = this.state.columns
        switch (sort.type) {
            case "string":
                this.sortStringBaseTable(data, sort)

                let nameColumn = { ...columns[0] }
                nameColumn.ad = sort.ad
                columns[0] = nameColumn
                this.setState({
                    data: data,
                })
                break;

            case "number":
                this.sortNumberBaseTable(data, sort)

                let ratingColumn = { ...columns[4] }
                ratingColumn.ad = sort.ad
                columns[4] = ratingColumn

                this.setState({
                    data: data
                })
                break;

            default:
                break;
        }
    }

    handleCheckBox(data) {
        const toDeleteList = this.state.toDeleteList
        if (toDeleteList.indexOf(data) === -1) {
            toDeleteList.push(data)
            this.setState({
                toDeleteList: toDeleteList
            })
        } else {
            toDeleteList.splice(toDeleteList.indexOf(data), 1)
            this.setState({
                toDeleteList: toDeleteList
            })
        }
    }

    handleSearch(data) {
        if (data.query === "") {
            this.getAllTenants()
        }
        tenantsService.searchTenants(this.props.userData.userId, data)
            .then((response => {
                this.setState({
                    isLoading: false,
                    data: [...response.data],
                    emptyState: {
                        showEmptyState: response.data.length === 0 ? true : false,
                        type: "noResult",
                    }
                })
            }))
            .catch((error) => {
                this.onClosePopup()
                this.setState({
                    toast: {
                        display: true,
                        text: 'Erreur lors de la recherche',
                        type: 'error'
                    },
                    emptyState: {
                        showEmptyState: true,
                        type: "error"
                    }
                })
                setTimeout(() => {
                    this.setState({
                        toast: {
                            display: false,
                            text: '',
                            type: ''
                        }
                    })
                }, 5000);
            })
    }

    render() {
        return (
            <div id='Tenants'>
                {this.state.dialog.display && <DialogBox text={this.state.dialog.text} btnText={"Supprimer"} type={this.state.dialog.type} onConfirme={this.confirmDelete} onClose={this.onCloseDialogBox} />}
                {this.state.dialogManyDelete.display && <DialogBox text={this.state.dialogManyDelete.text} btnText={"Supprimer"} type={this.state.dialogManyDelete.type} onConfirme={this.confirmManyDelete} onClose={this.onCloseDialogBox} />}
                {this.state.dialogError.display && <DialogBox text={this.state.dialogError.text} btnText={"Confirmer"} type={this.state.dialogError.type} onConfirme={this.onCloseDialogBox} onClose={this.onCloseDialogBox} />}
                <Toast show={this.state.toast.display} text={this.state.toast.text} type={this.state.toast.type} />
                {this.state.importTenantpopup.display && <UploadTenantsList handleUpload={this.handleUpload} onClose={this.onClosePopup} fileTypes={['xlsx']} />}
                {this.state.saveTenantpopup.display && <SaveTenant onSave={this.handleSave} onClose={this.onClosePopup} />}
                {this.state.readTenantpopup.display && <ReadTenant data={this.state.readTenant} onModify={this.openModifyTenantPopUp} onDelete={this.handleDelete} onClose={this.onClosePopup} />}
                {this.state.modifyTenantpopup.display && <ModifyTenant data={this.state.modifyTenant} onSave={this.handleModify} onClose={this.onClosePopup} />}
                <div id='tenant-header'>
                    <div id='tenant-header-left'>
                        <h1>Locataires</h1>
                        <p>Vous avez <span>{this.state.tenantCount}</span> {this.state.tenantCount > 1 ? 'locataires' : 'locataire'} {this.state.tenantCount > 1 ? 'enregistrés' : 'enregistré'}</p>
                    </div>
                    <div id='tenant-header-right'>
                        <SearchBarBaseTable handleSearch={this.handleSearch} />
                        <Button className={`success outline neutral`} onClick={() => this.openImportTenantPopUp()} text="Importer un fichier Excel" />
                        <Button onClick={() => this.openSaveTenantPopUp()} text="Ajouter un locataire" />
                    </div>
                </div>
                <BaseTable data={this.state.data} showDeleteBtn={this.state.toDeleteList.length > 0 ? true : false} handleManyDelete={this.handleManyDelete} handleCheckBox={this.handleCheckBox} emptyState={this.state.emptyState} isLoading={this.state.isLoading} columns={this.state.columns} sort={this.sortBaseTable} getInfos={this.openReadTenantPopUp} />
            </div>
        )
    }
}

export default withParams(Tenants)