import React from 'react'
import './EmptyState.css'
import { Player } from '@lottiefiles/react-lottie-player';
import Illustration from '../../assets/images/emptySate.svg'
import IllustrationError from '../../assets/images/500.svg'
import IllustrationNoResult from '../../assets/images/noResult.svg'

class EmptyState extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            //
        }
    }

    render() {
        return (
            <div id='EmptyState-box'>
                {this.props.type === "data" && <h4 id='data'>Vous n'avez pas de données pour le moment</h4>}
                {this.props.type === "data" && <Player
                    autoplay
                    loop
                    src="https://assets2.lottiefiles.com/private_files/lf30_rrpywigs.json"
                    style={{ width: '400px' }}
                >
                </Player>}

                {this.props.type === "noResult" && <h4 id='data'>Aucun résultat ne correspond à votre recherche</h4>}
                {this.props.type === "noResult" && <Player
                    autoplay
                    loop
                    src="https://assets5.lottiefiles.com/private_files/lf30_cgfdhxgx.json"
                    style={{ width: '400px' }}
                >
                </Player>}

                {this.props.type === "error" && <h3>OUPS...</h3>}
                {this.props.type === "error" && <h4>Quelque chose ne s'est pas <br /> passé comme prévue...</h4>}
                {this.props.type === "error" && <Player
                    autoplay
                    loop
                    src="https://cdn.lordicon.com/inrunzby.json"
                    style={{ width: '200px' }}
                >
                </Player>}

                {this.props.type === "unauthorized" && <h3>OUPS... ERREUR 401</h3>}
                {this.props.type === "unauthorized" && <h4>Vous n'êtes pas autorisez à voir ce <br/> ce qu'il ce passe ici.</h4>}
                {this.props.type === "unauthorized" && <Player
                    autoplay
                    loop
                    src="https://cdn.lordicon.com/kjiurikz.json"
                    style={{ width: '200px' }}
                >
                </Player>}
            </div>
        )
    }
}

export default EmptyState