import React from 'react'
import './button.css'

class Button extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            text: this.props.text,
            bold: this.props.bold || false
        }
    }

    render() {
        const classname = this.props.className
        return (
            <button disabled={this.props.disabled || false} style={this.state.bold === true ? { fontWeight: 'bold' } : {}} onClick={this.props.onClick} className={`myBtn ${classname}`}>{this.state.text}</button>
        )
    }
}

export default Button