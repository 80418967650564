import React from 'react'
import { FileUploader } from "react-drag-drop-files";
import Button from '../../Button/Button'
import './UploadTenantsList.css'
import Illustration from '../../../assets/images/uploadExcel.svg'

class UploadTenantsList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fileTypes: this.props.fileTypes,
            data: ""
        }
        this.handleDrop = this.handleDrop.bind(this)
        this.close = this.close.bind(this)
    }

    handleDrop(file) {
        let myFile = file
        let data = new FormData();
        data.append('uploadfile', myFile);
        this.setState({
            data: data
        })
    }

    handleDragOver(state) {
        if (state === true) {
            document.querySelector('.uploadForm').style.backgroundColor = '#97979720'
            document.querySelector('.uploadForm').style.borderColor = '#979797'
        } else {
            document.querySelector('.uploadForm').style.backgroundColor = '#5ab24918'
            document.querySelector('.uploadForm').style.borderColor = '#5ab249'
        }
    }

    handleSuccessDrop() {
        let formData = this.state.data
        let file = formData.get('uploadfile')

        return (
            <div id='dropSuccess'>
                <img src={Illustration} alt="" />
                <div>
                    <h4>{file.name}</h4>
                    <p>{file.size * 0.001} Ko</p>
                </div>
            </div>
        )
    }

    close() {
        this.setState({
            data: ""
        })
        this.props.onClose()
    }

    render() {
        return (
            <div id='UploadTenantsList'>
                <div id='fond' />
                <div id='popUpBox'>
                    <h3>Importer des locataires</h3>
                    {this.state.data === "" && <FileUploader
                        label="Sélectionnez votre fichier ou glisser le ici (.xlsx) "
                        classes='uploadForm'
                        multiple={false}
                        onDraggingStateChange={(e) => { this.handleDragOver(e) }}
                        handleChange={this.handleDrop}
                        types={this.state.fileTypes}
                    />}
                    {this.state.data !== "" && this.handleSuccessDrop()}
                    <div id='btnBox'>
                        <Button text="Annuler" className="neutral alert" onClick={() => this.close()} />
                        <Button disabled={this.state.data === ""} text="Importer" onClick={() => this.props.handleUpload(this.state.data)} />
                    </div>
                </div>
            </div>
        )
    }
}

export default UploadTenantsList
